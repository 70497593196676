import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';

import { AppSettingsService, ROUTE_ANIMATIONS_ELEMENTS, routeAnimations, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-landing-eurochem',
  templateUrl: './landing-eurochem.component.html',
  styleUrls: ['./landing-eurochem.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingEurochemComponent extends UnsubscribeComponent implements OnInit {
  public routeAnimationsElements: string = ROUTE_ANIMATIONS_ELEMENTS;

  public nowDate: Date = new Date();

  public isLoading: boolean = true;

  private hrid: string;

  constructor(
    private meta: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private webStorageService: WebStorageService,
  ) {
    super();
    this.meta.addTag({ name: 'og:title', content: 'Профилум' });

    this.isLoading = false;
  }

  public ngOnInit(): void {
    console.log('eurochem works');
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe),
        tap(params => {
          this.hrid = params['hrid'];
        }),
      )
      .subscribe();
  }

  public navigateToParentRegistration(): void {
    this.router.navigate(['/register-parent', { hrid: this.hrid }]);
  }

  public navigateToChildRegistration(): void {
    this.webStorageService.set(StorageKeys.ParentConsent, false);
    this.router.navigate(['/register-consent', { hrid: this.hrid }]);
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }
}
