import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { DropdownJsonFormControl } from './interfaces/dropdown.interfaces';

@Component({
  selector: 'prf-dropdown',
  standalone: true,
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  public control = input<DropdownJsonFormControl>();
  public formControlManager = input<FormControl>();

  public showClearIcon: boolean = false;

  @Input() public isSearch: boolean = false;

  public onSelectOption(): void{
    this.showClearIcon = !!this.formControlManager().value;
  }
}
