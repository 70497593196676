import { Injectable } from '@angular/core';
import { B2gEurochemService, EiType, ICheckReferralResponse } from '@profilum-library';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EurochemService {
  constructor(private b2gEurochemService: B2gEurochemService) {}

  public checkReferral(guid: string, consent?: boolean): Observable<ICheckReferralResponse> {
    return consent ? this.b2gEurochemService.checkReferral(guid, true) : this.b2gEurochemService.checkReferral(guid, false);
  }

  public getCities(): Observable<string[]> {
    return this.b2gEurochemService.getCities().pipe(map(response => response.cities));
  }

  public getEis(city: string, eiType: EiType) {
    return this.b2gEurochemService.getEis(city, eiType).pipe(map(response => response.eis));
  }
}
