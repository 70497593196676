<div class="field">
  <label [for]="control().name">{{ control().label }}</label>
  <div class="wrapper">
    <ng-select
      [class.hide-dropdown-icon]="showClearIcon"
      #dropdownRef
      dropdownPosition="bottom"
      notFoundText="Ничего не найдено"
      [formControl]="formControlManager()"
      [id]="control().name"
      [searchable]="isSearch"
      [clearable]="showClearIcon"
      (clear)="dropdownRef.close()"
      (change)="onSelectOption()"
    >
      @for (option of control().dropdownOptions; track option.value) {
        <ng-option [value]="option.value">
          {{ option.label }}
        </ng-option>
      }
    </ng-select>
  </div>
</div>
