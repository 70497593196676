<ng-template [ngIf]="pupilsInClass">
  <div *ngIf="!pupilsInClass.length; else pupilsTable" class="no-pupils">
    Здесь появятся {{ dictionary.Pupils }}, когда их добавят их родители
  </div>

  <ng-template #pupilsTable>
    <div class="pupils-table">
      <div class="sticky-header">
        <span
          class="pupils-table__header__pupils-count"
          [innerHTML]="
            declOfNum(pupilsInClass.length, [dictionary.Pupil | titlecase, dictionary.Pupils | titlecase, dictionary.Pupils | titlecase]) +
            ' ' +
            '(' +
            pupilsInClass.length +
            ')'
          "
        ></span>
      </div>

      <ul class="pupils-table__body">
        <li *ngFor="let pupil of pupilsInClass; let index = index">
          <div class="pupils-table__body__pupil-name">
            <span class="pupil-number">{{ index + 1 }}</span>
            {{ pupil.fullNameText }}
          </div>

          <div class="pupils-table__body__login">
            <ng-container *ngIf="!editPhone[index]; else editPhoneTemplate">
              {{ pupil.phoneNumberMask && !isKruzhkiVariant ? pupil.phoneNumberMask : pupil.email }}
              <img
                *ngIf="pupil.phoneNumberMask && !pupil.isActivated && !_successChanges[index] && accessEditNumber"
                src="profilum-assets/images/common/phone-edit.svg"
                class="pupils-table__body__login-icon-edit"
                (click)="showChangePhoneInput(index, pupil)"
                alt=""
              />
              <img
                *ngIf="_successChanges[index]"
                src="profilum-assets/images/common/green-tick.svg"
                class="pupils-table__body__login-icon-animation"
                alt=""
              />
            </ng-container>
            <ng-template #editPhoneTemplate>
              <form [formGroup]="form" autocomplete="off">
                <div class="pupils-table__form">
                  <input
                    type="text"
                    autocomplete="off"
                    class="pupils-table__form-input"
                    [class.error-field]="validField(f.phone)"
                    name="phone"
                    data-name="phone"
                    id="phone"
                    prfPhoneMask
                    [control]="f.phone"
                    [htmlInputElement]="phone"
                    formControlName="phone"
                    [textMask]="{ mask: phoneMask }"
                    (focusout)="focusOut(index)"
                    #phone
                  />
                </div>
              </form>
              <img
                *ngIf="!saveForbidden"
                src="profilum-assets/images/common/green-tick.svg"
                class="pupils-table__body__login-icon"
                alt=""
                (click)="showChangePhonePopup(pupil)"
              />
              <img
                *ngIf="saveForbidden"
                src="profilum-assets/images/common/grey-tick.svg"
                class="pupils-table__body__login-icon-disabled"
                alt=""
              />
              <img
                src="profilum-assets/images/common/close-phone-edit.svg"
                class="pupils-table__body__login-icon"
                (click)="closeChangePhoneInput(index)"
                alt=""
              />
            </ng-template>
          </div>
          <ng-container *ngIf="!isKruzhkiVariant">
            <div class="pupils-table__body__status">
              <span *ngIf="pupil.isActivated" class="pupils-table__body__status-activated" [tp]="tooltip"> Вошел в систему </span>
              <span *ngIf="!pupil.isActivated" class="pupils-table__body__status-not-activated" (click)="showResetPasswordPopup(pupil)">
                Не вошел в систему</span
              >
            </div>
          </ng-container>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>

<ng-template #tooltip>
  <prf-tooltip>{{ dictionary.Pupil | titlecase }} вошёл в систему и установил свой пароль</prf-tooltip>
</ng-template>
