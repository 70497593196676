import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationParentComponent } from './registration-parent/registration-parent.component';
import { RegistrationChildComponent } from './registration-child/registration-child.component';
import { LandingEurochemComponent } from './landing-eurochem/landing-eurochem.component';

const routes: Routes = [
  {
    path: 'register-parent',
    component: RegistrationParentComponent,
  },
  {
    path: 'register-parent/:hrid',
    component: RegistrationParentComponent,
    pathMatch: 'full',
  },
  {
    path: 'register-consent/:hrid',
    component: RegistrationChildComponent,
    pathMatch: 'full',
  },
  {
    path: 'register-direct/:hrid',
    component: LandingEurochemComponent,
    pathMatch: 'full',
  },
  {
    path: 'register-consent',
    component: RegistrationChildComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EurochemRoutingModule {}
