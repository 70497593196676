import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
/*TODO переделать и избавиться от postscribe*/
import postscribe from 'postscribe';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { GoogleTagManagerKZ } from 'app/shared/analytics/google-tag-manager/google-tag-manager.kz';
import { onlineConsultantScript } from 'app/shared/analytics/onlineConsultant/onlineConsultantScript';
import { MangoCallSiteScript } from 'app/shared/analytics/mango-call/mango-call-site';

if (environment.production) {
  enableProdMode();
}

if (environment.analytics && environment.enableOnlineConsultant) {
  if (
    !location.pathname.includes('bvb') &&
    !location.href.includes('saas') &&
    !location.origin.includes('kruzhki') &&
    !location.origin.includes('mosreg')
  ) {
    postscribe('#head', onlineConsultantScript);
  }

  if (
    location.origin.includes('yanao') ||
    location.origin.includes('bashkortostan') ||
    location.origin.includes('murmansk') ||
    location.origin.includes('pskov') ||
    location.origin.includes('class') ||
    location.origin.includes('localhost')
  ) {
    postscribe('#head', MangoCallSiteScript);
  }
}

if (location.host.includes('kz')) {
  postscribe('#head', GoogleTagManagerKZ);
}

platformBrowserDynamic().bootstrapModule(AppModule);
