import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ToggleJsonFormControlInterface } from './toggle.interface';

@Component({
  selector: 'prf-toggle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
  public controlConfig = input<ToggleJsonFormControlInterface>();
  public formControlManager = input<FormControl>();

  public onClick(chosenToggleValue: string): void {
    if (this.formControlManager().value === chosenToggleValue) return;
    this.formControlManager().setValue(chosenToggleValue);
  }
}
