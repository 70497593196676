<ng-container [ngSwitch]="activeLocation">
  <ng-container *ngSwitchCase="'mo'">
    <prf-page-footer-mosobl></prf-page-footer-mosobl>
  </ng-container>
  <ng-container *ngSwitchCase="'kz'">
    <prf-page-footer-kz></prf-page-footer-kz>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="baseFooter"></ng-container>
  </ng-container>
</ng-container>

<ng-template #baseFooter>
  <div class="footer-section" [ngClass]="{ 'no-padding-top': isNoPaddingTop }">
    <div class="footer-row">
      <div class="footer-text">© {{ yearNumber }} Профилум</div>
      <div class="footer-text">
        <a href="mailto:help@profilum.ru" class="footer-link">
          <strong>help&#64;profilum.ru</strong>
        </a>
        <a href="tel:88005004105" class="footer-link"> <strong>8 800 500 4105</strong> <br />(звонок бесплатный)</a>
      </div>
    </div>
  </div>
</ng-template>
