<ng-container>
  <div class="content-wrapper" *ngIf="!isParentResultShow; else showParentResults">
    <div class="heading-wrapper">
      <ng-container>
        <div class="child-form-view">
          <div class="child-form-view__title-wrapper">
            <h3 class="child-form-view__title">Информация о подростке</h3>
            <button *ngIf="child.id === idNoActivatedUser" (click)="togglePopup()" class="child-form-view__button">редактировать</button>
          </div>
          <prf-child-edit></prf-child-edit>
        </div>
      </ng-container>
      <ng-container [ngSwitch]="parentAction">
        <prf-static-notification
          *ngSwitchCase="AvailableActionForParent.COPY_INVITATION"
          title="Приглашение для подростка"
          text="Скопируйте и отправьте приглашение подростку любым удобным способом."
          subtitle="Текст приглашения"
          additionalText=" Привет, {{ child.firstName }}! Перейди по ссылке, чтобы получить доступ к платформе профориентации Профилум.
            Ссылка для входа: {{ registrationUrl }}
            Твой логин: +{{ child.phoneNumber }}."
          buttonText="Скопировать"
          (buttonClick)="copyMessage()"
          tooltipText="Скопировано в буфер обмена"
        ></prf-static-notification>
        <prf-static-notification
          *ngSwitchCase="AvailableActionForParent.START_DIAGNOSTIC"
          title="Пройдите диагностику 360"
          text="Поможет определить таланты и склонности вашего подростка. Прохождение займёт не более 30 минут."
          buttonText="Пройти диагностику"
          (buttonClick)="startOrContinueDiagnostic()"
        ></prf-static-notification>
        <prf-static-notification
          *ngSwitchCase="AvailableActionForParent.SEE_RESULTS"
          title="Результаты диагностики"
          text="Внутри — ваши оценки интересов и способностей ребёнка, а также рекомендуемые классы обучения"
          buttonText="Посмотреть результаты"
          (buttonClick)="showDiagnosticResults()"
        ></prf-static-notification>
      </ng-container>
    </div>
  </div>

  <ng-template [ngIf]="isEditMode">
    <div class="popup-overlay">
      <div class="popup-wrapper">
        <div class="popup-share" [class.popup_hidden]="confirmCloseEditPopup">
          <a (click)="confirmCloseEditPopup = !confirmCloseEditPopup" class="close-lb w-inline-block">
            <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия" />
          </a>
          <div class="popup-share-header">
            <h3 class="popup-share-title">Редактировать информацию о подростке</h3>
          </div>
          <div class="popup-share-content">
            <prf-child-edit [viewMode]="false" (editedUser)="changeChild($event)"></prf-child-edit>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="confirmCloseEditPopup">
    <div class="popup-overlay">
      <div class="popup-wrapper">
        <div class="popup-share popup-share_confirm">
          <a (click)="confirmCloseEditPopup = !confirmCloseEditPopup" class="close-lb w-inline-block">
            <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия" />
          </a>
          <div class="popup-share-header">
            <h3 class="popup-share-title popup-share-title_confirm">Прервать редактирование?</h3>
          </div>
          <div class="popup-share-content">
            <p class="popup-text">Изменения не сохранятся</p>
          </div>
          <div class="popup-share-buttons">
            <button class="popup-share-button popup-share-button_primary" (click)="confirmCloseEditPopup = !confirmCloseEditPopup">
              Нет
            </button>
            <button
              class="popup-share-button popup-share-button_secondary"
              (click)="togglePopup(); confirmCloseEditPopup = !confirmCloseEditPopup"
            >
              Прервать
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="editedSuccess">
    <div class="popup-overlay">
      <div class="popup-wrapper">
        <div class="popup-share popup-share_success">
          <a (click)="editedSuccess = !editedSuccess" class="close-lb w-inline-block">
            <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия" />
          </a>
          <div class="popup-share-header">
            <span class="icon-done"></span>
            <h3 class="popup-share-title popup-share-title_confirm">Изменения сохранены</h3>
          </div>
          <div class="popup-share-buttons">
            <button class="popup-share-button popup-share-button_primary" (click)="editedSuccess = !editedSuccess">Продолжить</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #showParentResults>
  <div class="parent-results-wrapper">
    <h2 class="test-result-h1 article-subtitle">Результаты диагностики</h2>
    <prf-results
      [child]="child"
      [sessionId]="parentTestInfoCurrentChild.parentTestSession"
      [showSwitch]="false"
      [showResultMenu]="false"
      [showOnlyParentResults]="true"
    ></prf-results>
    <a (click)="isParentResultShow = !isParentResultShow" class="close-lb w-inline-block">
      <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия" />
    </a>
  </div>
</ng-template>
