import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
  ApiCoursesMaterialsService,
  ApiLocationsService,
  ApiUsersService,
  B2gSaasService,
  ICourseMaterialExt,
  IGetCourseByClassResponse,
  ISchool,
  ISchoolClass,
  RamStorageService,
  StorageKeys,
  Storages,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { IAssigningCourseData } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import {
  TeacherClassCoursesService,
} from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import { MenuItems } from 'app/shared/enums/menu.enums';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { ICcuGroupsStatus, ITeacherGroup } from '../teacherPanel.interface';
import { TabsEnum } from './model';
import { ICourseMaterials } from '../../../../shared/common-components/course-materials/model';

@Component({
  selector: 'prf-teacher-start-page',
  templateUrl: './teacher-start-page.component.html',
  styleUrls: ['./teacher-start-page.component.scss'],
})
export class TeacherStartPageComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
  public classes: IAssigningCourseData[];
  public schoolName: string = '';
  public school: ISchool;
  public hasPupils: boolean = true;
  public isFirstSession: boolean = false;
  public classesWithoutCourse: IAssigningCourseData[] = [];
  public teacherHasNoClasses$: Observable<boolean>;
  public isShowingPopup$: Observable<boolean>;
  public dictionary: DictionaryType;
  public isKruzhkiVariant: boolean = false;
  public isUsefulTabTeacherNotificationHided: boolean = false;
  public groups: ITeacherGroup[];
  public activeTab: string = TabsEnum.LESSONS;
  protected readonly TabsEnum = TabsEnum;
  private classList: ISchoolClass[];
  public usefulMaterials: ICourseMaterials = {
    sections: [],
  };

  @ViewChild('usefulTemplate') public usefulTemplate: TemplateRef<any>;
  @ViewChild('classCardsTemplate') public classCardsTemplate: TemplateRef<any>;
  @ViewChild('forParentsTemplate') public forParentsTemplate: TemplateRef<any>;
  @ViewChild('content', { read: ViewContainerRef }) public content: ViewContainerRef;

  constructor(
    private b2gSaasService: B2gSaasService,
    private apiUsersService: ApiUsersService,
    private apiLocationsService: ApiLocationsService,
    private overlayBusyService: OverlayBusyService,
    private router: Router,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private rootScopeService: RootScopeService,
    private ramStorageService: RamStorageService,
    private webStorageService: WebStorageService,
    private yandexMetricsService: YandexMetricsService,
    private changeDetector: ChangeDetectorRef,
  ) {
    super();

    ramStorageService.set(StorageKeys.SelectedMenuItem, MenuItems.Lessons);
    this.teacherHasNoClasses$ = ramStorageService.get(StorageKeys.TeacherHasNoClasses);
    this.dictionary = rootScopeService.getDictionary();
    this.isKruzhkiVariant = location.origin.includes('kruzhki');
    this.isUsefulTabTeacherNotificationHided = this.webStorageService.get(StorageKeys.IsUsefulTabTeacherNotificationHided);
  }

  public ngOnInit(): void {
    this.overlayBusyService.show();
    this.isFirstSession = this.webStorageService.get(StorageKeys.IsFirstSession);
    this.isShowingPopup$ = this.ramStorageService.get(StorageKeys.TeacherWelcomePopup);
    if (this.isKruzhkiVariant) {
      this.b2gSaasService
        .getCcuGroups()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((groups: ICcuGroupsStatus) => {
          this.groups = groups.groups;
        });
    }

    this.b2gSaasService
      .getSchool()
      .pipe(
        switchMap((school: ISchool) => {
          this.school = school;
          // костыль для кружков - парсим строку с названием школы и меняем всё кроме "№" и цифр на ОКЦ
          if (this.isKruzhkiVariant) {
            this.school.number = this.school.number.replace(/[^№\d]+/g, '');
            this.school.number = this.dictionary.School + ' ' + this.school.number;
          }

          return this.apiLocationsService.getCity(this.school.cityId);
        }),
        switchMap(cityResponse => this.apiLocationsService.getRegion(cityResponse.city.regionId)),
        switchMap(regionResponse => {
          if (regionResponse?.files.length) {
            this.usefulMaterials.sections.push({
              title: '',
              items: regionResponse.files,
            });
          }

          return this.apiUsersService.getSchoolClassesTeachers();
        }),
        filter((classList: ISchoolClass[]) => {
          this.classList = classList;
          if (this.classList.length) {
            this.ramStorageService.set(StorageKeys.TeacherHasNoClasses, false);
          }
          this.hasPupils = !!this.classList.filter(el => el.pupilsCount > 1);

          if (!classList?.length) {
            this.ramStorageService.set(StorageKeys.TeacherHasNoClasses, true);
            this.overlayBusyService.hide();
            return false;
          }

          return true;
        }),
        switchMap((classList: ISchoolClass[]) => this.getCourseByClass(classList)),
        switchMap((assigningCourseData: IAssigningCourseData[]) => {
          const observers = [];

          assigningCourseData.forEach(({ course, schoolClass }) => {
            if (course && schoolClass) {
              observers.push(
                this.teacherClassCoursesService.getCourseMaterialWithPassedData(course as string, schoolClass as ISchoolClass).pipe(
                  map(course => {
                    course.nextLessonDate = this.teacherClassCoursesService.calculateCloserDate(course);
                    return { course, schoolClass, activeLesson: course.activeLesson };
                  }),
                ),
              );
            } else {
              this.classesWithoutCourse.push({
                course: null,
                activeLesson: null,
                schoolClass,
              } as IAssigningCourseData);
            }
          });

          return observers.length ? combineLatest(observers) : of([]);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (classes: IAssigningCourseData[]) => {
          this.classes = classes.concat(this.classesWithoutCourse);
          // костыль на смену "класс" на "группа" для Кружков
          if (this.isKruzhkiVariant) {
            this.classes.forEach((classItem: any) => {
              if (classItem.course) {
                classItem.course.name = classItem.course.name.replace('класс', 'группа');
              }
            });
          } else {
            classes.forEach((classItem: IAssigningCourseData) => {
              if ((classItem.course as ICourseMaterialExt).files.length) {
                this.usefulMaterials.sections.push({
                  title: `Для курса [${(classItem.course as ICourseMaterialExt).name}]`,
                  items: (classItem.course as ICourseMaterialExt).files,
                });
              }
            });
          }

          this.rootScopeService.classesArray = this.classes;
          this.rootScopeService.currentClassInfo = this.classes[0];
          this.overlayBusyService.hide();
        },
        () => {
          this.overlayBusyService.hide();
        },
      );
  }

  public ngAfterViewInit(): void {
    this.ramStorageService
      .get(StorageKeys.UserId, Storages.Local)
      .pipe(take(1))
      .subscribe((userId: string) => {
        this.yandexMetricsService.userParams(YmItems.UserTeacherParams, {
          client_type: 'teacher',
          User_id: userId,
        });
        this.yandexMetricsService.params(YmItems.TeacherParam, {
          visit_type: 'teacher',
          User_id: userId,
        });
      });

    if (!this.isKruzhkiVariant) {
      this.selectTab(this.activeTab);
    }
    this.changeDetector.detectChanges();
  }

  private changeTabContent(view: ViewRef): void {
    this.content.clear();
    this.content.insert(view);
  }

  public handleClassCardClick(classData: IAssigningCourseData): void {
    if (classData.course) {
      this.selectClass(classData.course as ICourseMaterialExt, classData.schoolClass);
    } else {
      this.goToClass(classData.schoolClass);
    }
  }

  public selectClass(course: ICourseMaterialExt, selectedClass: ISchoolClass): void {
    const currentClass = this.classes.find(el => {
      return (el.schoolClass as ISchoolClass).id === selectedClass.id;
    });
    this.webStorageService.set(StorageKeys.ClassNumber, selectedClass.number);
    this.webStorageService.set(StorageKeys.ClassLetter, selectedClass.letter);
    this.webStorageService.set(StorageKeys.CourseMaterialId, course.id);
    this.rootScopeService.currentClassInfo = currentClass;
    this.isKruzhkiVariant
      ? this.router.navigate(['/class-courses', selectedClass.id])
      : this.router.navigate(['/teacher-lessons', selectedClass.id]);
  }

  public goToClass(selectedClass: ISchoolClass): void {
    const currentClass = this.classes.find(el => {
      return (el.schoolClass as ISchoolClass).id === selectedClass.id;
    });
    this.webStorageService.set(StorageKeys.ClassNumber, selectedClass.number);
    this.webStorageService.set(StorageKeys.ClassLetter, selectedClass.letter);
    this.rootScopeService.currentClassInfo = currentClass;
    this.router.navigate(['/class-diagnostic', selectedClass.id]);
  }

  public showDetailGroup(id: string): void {
    const currentInfoClass: IAssigningCourseData = this.classes.find((el: IAssigningCourseData) => {
      return (el.schoolClass as ISchoolClass).id === id;
    });
    this.selectClass(currentInfoClass.course as ICourseMaterialExt, currentInfoClass.schoolClass as ISchoolClass);
  }

  private getCourseByClass(classList: ISchoolClass[]): Observable<IAssigningCourseData[]> {
    return combineLatest(
      classList.map(schoolClass => {
        return this.apiCoursesMaterialsService.getCourseByClass(schoolClass.id).pipe(
          map((response: IGetCourseByClassResponse) => {
            return { course: response?.courseIds[0] ?? null, schoolClass: schoolClass };
          }),
        );
      }),
    );
  }

  public selectTab(tab: string): void {
    this.activeTab = tab;

    switch (this.activeTab) {
      case TabsEnum.LESSONS:
        this.changeTabContent(this.classCardsTemplate.createEmbeddedView(null));
        break;
      case TabsEnum.USEFUL:
        this.changeTabContent(this.usefulTemplate.createEmbeddedView(null));
        break;
    }
  }

  public closePopup(): void {
    this.ramStorageService.set(StorageKeys.TeacherWelcomePopup, false);
  }
}
