export const EUROCHEM_DICTIONARY = {
  GENERAL: {
    FORGET_PASSWORD: 'Не помню пароль',
    LOGIN: 'Войти',
    CANT_LOGIN: 'Не получается войти',
    EMAIL: 'Электронная почта',
    PHONE: 'Телефон',
    PASSWORD: 'Пароль',
    REPEAT_PASSWORD: 'Повторите пароль',
    SEND: 'Отправить',
    CONTINUE: 'Продолжить',
    ALL_RIGHT: 'Все верно',
    EDIT: 'Редактировать',
    PARENT: 'Родитель',
    PUPIL: 'Подросток',
  },
  LOGIN: {
    BASIS_OF_PLATFORM: 'Проводится на базе платформы',
    KRUZHKI_ADDITIONAL_INFO_TITLE: 'Кружок',
    KRUZHKI_ADDITIONAL_INFO_TEXT: '«Творчество как профессия»',
    MOSOBL_ADDITIONAL_ENTER_BUTTON_TEXT: 'Войти через ФГИС «Моя школа»',
  },
  PASSWORD_RECOVERY: {
    METADATA_NAME: 'og:title',
    METADATA_CONTENT: 'Восстановление пароля',
    FORGOT_PASSWORD_QUESTION: 'Забыли пароль?',
    FORGOT_PASSWORD_DESCRIPTION: 'Введите телефон или почту, на которую зарегистрирован аккаунт, чтобы создать новый пароль',
    FORGOT_PASSWORD_KRUZHKI_DESCRIPTION: 'Введите почту, на которую зарегистрирован аккаунт, чтобы создать новый пароль',
    PASSWORD_RECOVERY_BY_PHONE_CALL_TITLE: 'Восстановление пароля по номеру телефона:',
    PASSWORD_RECOVERY_BY_PHONE_CALL_STEP_1: 'Закажите звонок',
    PASSWORD_RECOVERY_BY_PHONE_CALL_STEP_2: 'В течение нескольких минут на ваш номер позвонит бот, не берите трубку',
    PASSWORD_RECOVERY_BY_PHONE_CALL_STEP_3: 'Укажите последние 4 цифры номера этого входящего вызова для сброса пароля',
    PASSWORD_RECOVERY_REQUEST_CALL: 'Заказать звонок',
    RETURN_TO_LOGIN: 'Вернуться на вход',
    PASSWORD_RECOVERY_BY_PHONE_CALL_FORM_TITLE: 'Укажите последние 4 цифры номера входящего вызова',
    CALL_WILL_SENT_TO_NUMBER: 'Звонок поступит на номер',
    CALL_WILL_SENT_TO_NUMBER_END:
      'Укажите последние 4 цифры входящего номера телефона. Если звонка нет больше трех минут - напишите в поддержку',
    IF_NO_CALL_TO_NUMBER: 'Если звонка нет больше трех минут — напишите в поддержку',
    REQUEST_NEW_CALL: 'Запросить новый звонок',
    NOT_VALID_REFERRAL1: 'Похоже, эта ссылка не работает.',
    NOT_VALID_REFERRAL2: 'Попробуйте зайти на сайт по ссылке, которую передал учитель.',
    NOT_VALID_REFERRAL3: 'Если ошибка повторяется, попросите учителя отправить вам новую ссылку для регистрации.',
    TECHNICAL_ERROR_TITLE: 'Техническая ошибка, повторите попытку позже',
    TECHNICAL_ERROR_DESCRIPTION: 'К сожалению, сейчас мы не можем прислать вам новый пароль. Повторите попытку через 1 час.',
    EMAIL_FINAL_SCREEN_TITLE: 'Если email верный, на него придет письмо для восстановления пароля.',
    EMAIL_FINAL_SCREEN_DESCRIPTION: 'Если возникли трудности, напишите в поддержку.',
    SMS_FINAL_SCREEN_TITLE: 'Сообщение отправлено',
    SMS_FINAL_SCREEN_DESCRIPTION: 'Если в течение 10 минут ничего не пришло — обратитесь в поддержку',
    SET_PASSWORD_TITLE: 'Придумайте новый пароль',
    CONTACT_SUPPORT: 'Обратиться в поддержку',
    SET_PASSWORD_SIX_CHARACTERS_ERROR: 'от 6 символов',
    SET_PASSWORD_LETTERS_ERROR: 'строчные и заглавные латинские буквы',
    SET_PASSWORD_ONE_DIGIT_ERROR: '1 цифра',
    SET_PASSWORD_DOESNT_MATCH_ERROR: 'Пароли не совпадают',
    SET_PASSWORD_SUCCESS_TITLE: 'Пароль изменен!',
    SET_PASSWORD_SUCCESS_DESCRIPTION: 'Используйте его для входа в систему',
    PHONE_NUMBER_USED: 'Пользователь с таким номером телефона уже зарегистирован',
  },
  REGISTRATION: {
    SURNAME_PLACEHOLDER: 'Фамилия',
    NAME_PLACEHOLDER: 'Имя',
    EMPTY_EMAIL: 'E-mail не введен',
    STEP_ONE_TITLE: 'Данные родителя',
    STEP_TWO_TITLE: 'Данные подростка',
    STEP_THREE_TITLE: 'Проверьте данные',
    STEP_FOUR_TITLE: 'Пригласите подростка',
    PARENT: {
      PASSWORD_PLACEHOLDER: 'Придумайте пароль',
      INSERT_CHILD_INFO: 'Введите данные ребенка',
    },
    PUPIL: {
      PHONE_PLACEHOLDER: 'Телефон подростка',
      BIRTHDATE_PLACEHOLDER: 'Дата рождения',
    },
  },
};
