<div *ngIf="!isLoading; else loading" class="land-body scrollable-container">
  <div class="land-main-section-2">
    <div class="land-header-row-2">
      <a routerLink="/" class="land-header-logo-2 w-inline-block">
        <div class="profilum_logo"></div>
      </a>
      <div class="land-header-column">
        <button [ngClass]="routeAnimationsElements" routerLink="/login" class="land-header-button w-button">ВХОД</button>
      </div>
    </div>
    <div class="land-content">
      <img [ngClass]="routeAnimationsElements" src="./profilum-assets/images/eurochem/land-main-image.png" alt="" class="land-main-image" />

      <div class="land-main-block">
        <div class="main-block">
          <h1 class="land-h1-2" [ngClass]="routeAnimationsElements">
            <span>
              ВИРТУАЛЬНЫЕ<br />
              ПРОФПРОБЫ
            </span>
          </h1>
          <p class="land-subtitle-h1-2" [ngClass]="routeAnimationsElements">
            Если ты ученик и тебе меньше 14 лет, <br />
            позови родителей для регистрации
          </p>
          <div class="buttons-block">
            <button [ngClass]="routeAnimationsElements" class="button button-parent" (click)="navigateToParentRegistration()">
              <div class="button-text-container">
                <b>Я РОДИТЕЛЬ</b><br />
                Регистрирую ребенка, которому меньше 14 лет
              </div>
              <div class="arrow arrow-white"></div>
            </button>
            <button [ngClass]="routeAnimationsElements" class="button button-pupil" (click)="navigateToChildRegistration()">
              <div class="button-text-container">
                <b>Я УЧЕНИК</b><br />
                Мне больше 14 лет, регистрируюсь сам
              </div>
              <div class="arrow arrow-green"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="land-footer">
    <div class="w-container footer-container">
      <div class="footer-sponsors-block">
        <div class="footer-information">
          <div class="land-footer-text">© Профилум.<br />Все права защищены.</div>
          <div>
            <div>
              <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                'SHARED.USED_PRIVACY_DATA' | translate
              }}</a>
            </div>
            <div>
              <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                'SHARED.AGREEMENT.USER_AGREEMENT' | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-mobile">
      <div class="footer-mobile-down">
        <div class="footer-mobile-info">
          <div class="footer-mobile-contacts">
            <div>
              <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                'SHARED.USED_PRIVACY_DATA' | translate
              }}</a>
            </div>
            <div>
              <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                'SHARED.AGREEMENT.USER_AGREEMENT' | translate
              }}</a>
            </div>
            <div class="land-footer-text">© Профилум.<br />Все права защищены.</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

<ng-template #loading></ng-template>
