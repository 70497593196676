<div class="field">
  <label for="{{ control().name }}">{{ label }}</label>
  <div class="wrapper">
    <input
      class="input"
      type="text"
      readonly
      [formControl]="formControlManager()"
      [id]="control().name"
      [class.error-field]="control().errors"
      [placeholder]="control().options?.placeholder || ''"
      [autocomplete]="control().options?.autocomplete || 'off'"
    />
    <i class="icon calendar"></i>
  </div>
</div>
