import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { B2gSaasService, StorageKeys, UserActionsService, WebStorageService, YmItems } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-pupil-menu-bottom',
  templateUrl: './pupil-menu-bottom.component.html',
  styleUrls: ['./pupil-menu-bottom.component.scss'],
})
export class PupilMenuBottomComponent extends UnsubscribeComponent {
  @Input() public imagePath: string;
  public isLoading: boolean = true;
  @Input() public initials: string = '';
  @Input() public root: string = 'profile';
  @Input() public onbording: boolean = false;
  @Output() navigateToProfile = new EventEmitter();

  constructor(
    private router: Router,
    private b2gSaasService: B2gSaasService,
    private userActionsService: UserActionsService,
    private webStorageService: WebStorageService,
  ) {
    super();
    this.userActionsService.setInitializationTime([YmItems.S_lessons, YmItems.S_profile, YmItems.S_Professions]);
  }

  public selectMain(): void {
    this.navigateToProfile.emit();
    this.router.navigate([this.webStorageService.get(StorageKeys.AssignedCourseExists) ? '/pupil-edit-profile' : '/profile']);
  }

  public selectLessons(): void {
    this.router.navigate(['/pupil']);
  }
}
