import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ClipboardService } from 'ngx-clipboard';
import { ApiUsersService, RamStorageService, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { IChildInfo, IParentTestInfo } from '../../parent.interface';
import { ETestType } from '../../../../player/anketa/anketa.enums';
import { ICreateSessionData } from '../../../../../shared/interfaces/icreatesessiondata.interface';
import { Gender } from '../../../../../shared/enums/gender.enums';

export enum AvailableActionForParent {
  COPY_INVITATION,
  START_DIAGNOSTIC,
  SEE_RESULTS,
  NO_ACTIONS,
}

@Component({
  selector: 'prf-parent-welcome-page',
  templateUrl: './parent-welcome-page.component.html',
  styleUrls: ['./parent-welcome-page.component.scss'],
})
export class ParentWelcomePageComponent extends UnsubscribeComponent implements OnDestroy {
  public parentAction: number;
  public parentTestInfoCurrentChild: IParentTestInfo;
  public child: IChildInfo;
  public showTooltip: boolean = false;
  public isEditMode: boolean = false;
  public confirmCloseEditPopup: boolean = false;
  public editedSuccess: boolean = false;
  public isParentResultShow: boolean = false;
  public registrationUrl: string;
  public readonly idNoActivatedUser: string = '00000000-0000-0000-0000-000000000000';
  protected readonly AvailableActionForParent: any = AvailableActionForParent;
  @Output() copyChildCode = new EventEmitter<boolean>();
  @Input() registrationCode: string;
  @Input() set childInfo(child: IChildInfo) {
    if (!child) return;

    this.child = child;
    this.parentTestInfoCurrentChild = child.parentTestInfo;
    this.isParentResultShow = false;

    this.setParentAction();
    this.concatRegistrationUrl();
  }

  constructor(
    private clipboardService: ClipboardService,
    private router: Router,
    private webStorageService: WebStorageService,
    private overlayBusyService: OverlayBusyService,
    private apiUsersService: ApiUsersService,
    private ramStorageService: RamStorageService,
  ) {
    super();
  }

  public copyCode(): void {
    this.copyChildCode.emit(false);
    this.showTooltip = true;
    this.timer = setTimeout(() => (this.showTooltip = false), 3000);
  }

  public changeChild(event: IChildInfo): void {
    this.child = event;
    this.ramStorageService.set(StorageKeys.CurrentChild, event);
    this.togglePopup();
    this.editedSuccess = true;
  }

  public togglePopup(): void {
    this.isEditMode = !this.isEditMode;
    // this.isEditMode ? Helper.disablePageScrolling() : Helper.enablePageScrolling();
  }

  public copyMessage() {
    const copyText: string = `
    Привет, ${this.child.firstName}! \r\n
    Перейди по ссылке, чтобы получить доступ к платформе профориентации Профилум. \n
    Ссылка для входа: ${this.registrationUrl} \n
    Твой логин: +${this.child.phoneNumber}`;

    this.clipboardService.copy(copyText);

    this.showTooltip = true;
    this.timer = setTimeout(() => (this.showTooltip = false), 3000);
  }

  private concatRegistrationUrl(): void {
    this.registrationUrl = location.origin + '/code-registration?code=' + this.child.registrationCode;
  }

  public startOrContinueDiagnostic(): void {
    if (this.parentTestInfoCurrentChild.parentTestSession) {
      this.router.navigate(['/test-na-professiyu/' + this.parentTestInfoCurrentChild.parentTestSession]);
    } else {
      this.redirectOnNewSession();
    }
  }

  public redirectOnNewSession(): void {
    this.overlayBusyService.show();

    const requestData: any = {
      data: {
        user: {
          gender: this.webStorageService.get<string>(StorageKeys.UserGender)?.toLowerCase(),
        },
        ScreeningTestType: 'Screening360',
      },
      ReferralUserId: this.webStorageService.get(StorageKeys.RefferalUserId),
    };

    const referralUserGender: string = this.webStorageService.get<string>(StorageKeys.RefferalUserGender);
    if (referralUserGender == Gender.Female || referralUserGender == Gender.Male) {
      requestData.data.user.gender = referralUserGender.toLowerCase();
    }

    this.apiUsersService
      .createSessionParents(requestData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((sessionData: ICreateSessionData) => {
        this.webStorageService.set(StorageKeys.LastSession, {
          sessionID: sessionData.sessionId,
          user: { testType: ETestType.Test360 },
        });
        this.overlayBusyService.hide();
        this.router.navigate(['/test-na-professiyu/' + sessionData.sessionId]);
      });
  }

  public showDiagnosticResults(): void {
    this.isParentResultShow = true;
  }

  private setParentAction(): void {
    if (this.child.id === this.idNoActivatedUser) {
      this.parentAction = AvailableActionForParent.COPY_INVITATION;
    } else if (this.parentTestInfoCurrentChild.isParentFromPskov && !this.parentTestInfoCurrentChild.isParentCompleteTest) {
      this.parentAction = AvailableActionForParent.START_DIAGNOSTIC;
    } else if (this.parentTestInfoCurrentChild.isParentFromPskov && this.parentTestInfoCurrentChild.isParentCompleteTest) {
      this.parentAction = AvailableActionForParent.SEE_RESULTS;
    } else {
      this.parentAction = AvailableActionForParent.NO_ACTIONS;
    }
  }
}
