<section class="parent">
  <prf-menu [root]="'parent'"></prf-menu>
  <div class="content-section">
    <div class="container mobile-fullwidth" *ngIf="dataLoaded">
      <prf-page-header [childTested]="selectedChild.isTested" [title]="'Главная'"></prf-page-header>

      <div class="parent-welcome-widget">
        <div class="parent-welcome-widget__wrapper">
          <h3 class="parent-welcome-widget__title">Спасибо за регистрацию!</h3>
          <p class="parent-welcome-widget__desc">
            Вы зарегистрировались в системе. На этой странице вы можете <br />
            скопировать приглашение для ребёнка или отредактировать данные.
          </p>
        </div>
      </div>

      <ng-container *ngIf="userProfile.children.length > 0">
        <prf-children-tabs
          *ngIf="dataLoaded"
          [children]="userProfile.children"
          (selectChild)="onSelectChild($event)"
          (addChild)="$event ? showAddLinkPopup(true) : null"
        >
        </prf-children-tabs>

        <prf-children-tabs-container
          *ngIf="dataLoaded"
          [child]="selectedChild"
          [sessionIDParent]="sessionIDParent"
          [favoriteProfessions]="favoriteProfessions"
        >
        </prf-children-tabs-container>
      </ng-container>
      <prf-page-footer></prf-page-footer>
    </div>
  </div>

  <prf-child-registration *ngIf="isShowChildReg" (closePage)="isShowChildReg = !$event"> </prf-child-registration>
  <prf-static-task-modal></prf-static-task-modal>
</section>

<prf-add-link-popup *ngIf="linkPopupShow" (dataLinks)="confirmClass($event)" (closeModal)="closeModal()"></prf-add-link-popup>

<ng-container *ngIf="linkChecked">
  <div class="prf-popup-overlay">
    <div class="prf-popup">
      <a (click)="closeModal()" class="close-lb w-inline-block">
        <img src="./assets/images/icons/close-grey.svg" alt="Иконка закрытия модального окна" />
      </a>
      <div class="prf-header">
        <h3 class="prf-popup-title">Добавить в {{ currentClass.classNumber }}«{{ currentClass.classLetter }}»?</h3>
      </div>
      <div class="prf-popup-content">
        <p class="prf-popup-text">
          Вы зарегистрируете подростка <br />
          в {{ currentClass.classNumber }}«{{ currentClass.classLetter }}», {{ currentSchoolName }}
        </p>
      </div>

      <div class="prf-popup-buttons prf-popup-buttons_column">
        <button (click)="confirmAdding(true)" class="prf-popup-button prf-popup-button_primary">Добавить</button>
        <button (click)="confirmAdding(false)" class="prf-popup-button prf-popup-button_text">Отменить</button>
      </div>
    </div>
  </div>
</ng-container>

<prf-add-child-popup
  *ngIf="addChildPopup"
  [schoolId]="regDataPupil.schoolId"
  [classInfo]="currentClass"
  (closeModal)="closeModal()"
  (successAdding)="loadUserProfile(); addChildPopup = false"
></prf-add-child-popup>

<!-- Loader -->
<ng-template #loader>
  <div class="loader-wrapper button-content">
    <div class="loadingio-spinner-spin-7r4u7e5tvu">
      <div class="ldio-vvw3hsbhf9p">
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>
