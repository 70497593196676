import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'prf-support-text',
  templateUrl: './support-text.component.html',
  styleUrls: ['./support-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportTextComponent {
  public openOnlineConsultantWidget(event: Event): void {
    event.preventDefault();
    (document.querySelector('.online-chat-root-TalkMe #supportTrigger') as HTMLElement | null)?.click();
  }
}
