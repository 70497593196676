import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-restriction-banner',
  templateUrl: './schooladmin-success-banner.component.html',
  styleUrls: ['./schooladmin-success-banner.component.scss'],
})
export class SchooladminSuccessBannerComponent {
  @Input() schoolClassId: string;
}
