import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ResultsService } from 'app/pages/results/results.service';
import { MenuService } from 'app/shared/dashboard/menus/menu/menu.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of } from 'rxjs';
import { ICurrentCLassData } from 'app/pages/control-panel/teacher/teacher-dashboard/teacher-dashboard.component';
import { ApiTemporaryService } from '@profilum-library';
import {
  SpiderChartFiguresEnum,
  SpiderChartThemeEnum,
} from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

@Component({
  selector: 'prf-class-test-results',
  templateUrl: './class-test-results.component.html',
  styleUrls: ['./class-test-results.component.scss'],
})
export class ClassTestResultsComponent implements OnInit {
  schoolClass: ICurrentCLassData;
  classResults: any;
  statistic: any;
  completedTests: Array<any> = [];
  skillsAttainmentData: any;
  dataFetched: boolean = false;
  isMobile: boolean = false;
  parentLink: string = '';
  public completedTestPupilsCount: number = 0;

  private mockResults = [
    {
      axis: 'Конструирование и математика',
      value: 0,
    },
    {
      axis: 'Навыки общения',
      value: 0,
    },
    {
      axis: 'Решение проблем',
      value: 0,
    },
    {
      axis: 'Творческие способности',
      value: 0,
    },
    {
      axis: 'Физические навыки',
      value: 0,
    },
    {
      axis: 'Навыки работы с информацией',
      value: 0,
    },
  ];

  @Output() hasResults = new EventEmitter<boolean>();
  @Input() status: string;
  @Input() data: any;

  @Input() set schoolClassSetter(value: ICurrentCLassData) {
    if (value) {
      this.schoolClass = value;
      this.parentLink = value.parentLink;
      this.detSchoolClassData().pipe(take(1)).subscribe();
    }
  }

  constructor(private apiTemporaryService: ApiTemporaryService, private resultService: ResultsService, private menuService: MenuService) {}

  ngOnInit() {
    this.isMobile = window.innerWidth <= 768;
  }

  detSchoolClassData(): Observable<any> {
    return this.apiTemporaryService.getSchoolClassPage(this.schoolClass.classId).pipe(
      switchMap(classResult => {
        this.classResults = classResult;
        if (this.classResults && this.classResults.results && this.classResults.results.length > 0) {
          this.parseResultData(this.classResults);
          return observableForkJoin([
            this.apiTemporaryService.getSchoolClassStatistic(this.schoolClass.classId),
            this.apiTemporaryService.getClassTestingSessions(this.schoolClass.classId),
          ]).pipe(
            tap(([statistic, testsSessions]) => {
              this.statistic = statistic;
              this.completedTests = testsSessions;
              this.completedTestPupilsCount =
                statistic.totalPupilsCount < testsSessions.length ? statistic.totalPupilsCount : testsSessions.length;
              this.hasResults.emit(this.completedTests.length > 0);
            }),
          );
        } else {
          this.hasResults.emit(false);
          this.skillsAttainmentData = [this.mockResults, []];
          this.setColorsSkills(this.skillsAttainmentData);
          this.dataFetched = true;
          return of(null);
        }
      }),
    );
  }

  private parseResultData(results: any) {
    let skillsAttainment = results.results.filter(d => d.objectType === 'SkillAttainment');
    this.skillsAttainmentData = this.resultService.transformSkills(skillsAttainment, null);
    this.setColorsSkills(this.skillsAttainmentData);
    this.dataFetched = true;
  }

  public setColorsSkills(data) {
    data[0][0].color = '#f743de';
    data[0][1].color = '#fdcf00';
    data[0][2].color = '#36acff';
    data[0][3].color = '#13e5db';
    data[0][4].color = '#9375fb';
    data[0][5].color = '#fd651a';
    return data;
  }

  openResults() {
    this.menuService.openResults();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 768;
  }

  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;
}
