<div class="field">
  <label for="{{ controlConfig.name }}" [class.error-label]="!isValid && !focused && formControlManager.touched">{{ controlConfig.label }}</label>
  <div class="wrapper">
    <input
      class="input"
      [formControl]="formControlManager"
      [type]="controlConfig.type === 'password' ? 'password' : 'text'"
      [class.error-field]="!isValid && !focused && formControlManager.touched"
      [class.disabled]="controlConfig?.disabled"
      [id]="controlConfig.name"
      [placeholder]="controlConfig.options?.placeholder || ''"
      [autocomplete]="controlConfig.options?.autocomplete || 'off'"
      (blur)="onBlur()"
      (focus)="onFocus()"
    />
    @if (isIconVisible) {
      <i class="icon" [ngClass]="controlConfig?.icon" (click)="onIconClicked()"></i>
    }
  </div>
</div>
