<div class="content-column _2" *ngIf="dataFetched">
  <prf-class-widget
    *ngIf="status === 'childrenDiagnosed'"
    [schoolClassId]="schoolClass.classId"
    [showSmall]="true"
    [access]="true"
    [status]="status"
  >
  </prf-class-widget>

  <prf-class-widget
    *ngIf="status === 'doneWithChildren' || status === 'childrenDiagnosed'"
    [schoolClassId]="schoolClass.classId"
    [showSmall]="true"
    [status]="status"
    [data]="data"
    [shortRegLink]="parentLink"
  ></prf-class-widget>

  <div class="test-block" *ngIf="!isMobile">
    <h3 class="test-block-title">{{ 'SHARED.CLASS_RESULTS' | translate }}</h3>
    <div class="class-test-results">
      {{
        completedTestPupilsCount
          ? 'Тест прошли ' + completedTestPupilsCount + ' из ' + statistic.totalPupilsCount
          : 'После диагностики появится статистика по вашему классу'
      }}
    </div>

    <div class="graphic">
      <prf-spider-chart-custom [inputSpiderChartData]="skillsAttainmentData"
                               [theme]="SpiderChartThemeEnum.GREEN"
                               [figureType]="SpiderChartFiguresEnum.HEXAGON"
      ></prf-spider-chart-custom>
    </div>
    <div class="parameters">
      <div class="parameter-block" *ngFor="let skill of skillsAttainmentData[0]">
        <div>{{ skill.axis }}</div>
        <div class="parameter-line">
          <div class="parameter-line-color" [ngStyle]="{ width: skill.value * 100 + '%', 'background-color': skill.color }"></div>
          <div class="parameter-number">{{ skill.value * 100 | number : '1.0-0' }}</div>
        </div>
      </div>
    </div>

    <a
      class="button achievements-button w-button"
      [class.inactive]="!completedTests.length"
      routerLink="/results"
      [queryParams]="{ schoolClassId: schoolClass.classId }"
      >{{ 'SHARED.WATCH_RESULTS' | translate }}</a
    >
  </div>
</div>
