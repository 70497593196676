<ng-container *ngIf="!editClass">
  <section class="school-statistic-block" *ngIf="_schoolStatisticFlag">
    <div class="info-tab _classes">
      <div class="info-tab-title">{{ 'SHARED.OF_CLASSES' | translate }}</div>
      <div class="info-tab-content">
        {{ schoolStatistic.classesCount }}
      </div>
    </div>
    <div class="info-tab _teachers">
      <div class="info-tab-title">{{ 'SHARED.OF_TEACHERS' | translate }}</div>
      <div class="info-tab-content">{{ schoolStatistic.teachersCount }}</div>
    </div>
    <ng-container *ngIf="isTestStatisticsShown">
      <div class="info-tab _tested">
        <div class="info-tab-title">{{ 'SHARED.OF_TESTED_PUPILS' | translate }}</div>
        <div class="info-tab-content">{{ schoolStatistic.completedTestsCount }}</div>
      </div>
      <div class="info-tab _trajectory">
        <div class="info-tab-title">{{ 'SHARED.OF_PUPILS_WITH_TRAJECTORY' | translate }}</div>
        <div class="info-tab-content">{{ schoolStatistic.selectedProfessionsCount }}</div>
      </div>
    </ng-container>
  </section>

  <section class="classes" *ngIf="mergingArrays; else welcome">
    <a class="button w-button" routerLink="/schooladmin/new-class">{{ 'SHARED.CREATE_NEW_CLASS' | translate }}</a>
    <div class="classes-section">
      <div class="content-row">
        <div class="classes-container">
          <div class="class-div">
            <div class="class-header _2" [class.fixed]="isFixed">
              <div class="class-row class-header-row-3">
                <div class="class-column _9"></div>
                <div class="class-column-class _4">
                  <div>Класс</div>
                </div>
                <div class="teacher-class-header">
                  <div>{{ 'CODE_REGISTRATION_KZ.TEACHER' | translate }}</div>
                </div>
                <div class="class-column _6">
                  <div>Кол-во учеников</div>
                  <div class="class-test-row">
                    <div class="class-test-column">
                      <div>Всего</div>
                    </div>
                    <div *ngIf="isTestStatisticsShown" class="class-test-column">
                      <div>Прошли тест</div>
                    </div>
                  </div>
                </div>
                <div class="class-column _11"></div>
              </div>
            </div>
            <div class="classes-list-block _2 scrollable-container">
              <div class="students-list">
                <!--CLASS-->
                <ng-container *ngIf="loadedClasses">
                  <a
                    class="class-list-item-2"
                    *ngFor="let classInfo of mergingArrays; let i = index"
                    [ngClass]="mergingArrays.length === i ? 'no-underline' : ''"
                    (click)="routeToClass($event, classInfo.id)"
                  >
                    <div class="class-row-2 classes-row">
                      <!--Number-->
                      <div class="class-column _9">
                        <!-- <div class="class-header-number _2"></div> -->
                      </div>
                      <!--Class name-->
                      <div class="class-row-div">
                        <div class="classes-column-class classes-link">
                          <p class="class-name cut-text" [tp]="tooltip" [tpData]="classInfo">
                            {{ classInfo.number }} «{{ classInfo.letter }}»
                          </p>
                        </div>

                        <!--Teacher name-->
                        <a class="classes-column-teacher classes-link">
                          <div class="classes-column-teacher-item">
                            <div
                              class="class-photo w-inline-block"
                              [ngStyle]="{
                                'background-image': classInfo && classInfo.imagePath ? 'url(' + classInfo.imagePath + ')' : ''
                              }"
                            ></div>
                            <div class="class-column-name">
                              <div>
                                {{ classInfo.lastName }} {{ classInfo.firstName }}
                                {{ classInfo.middleName }}
                              </div>
                              <div *ngIf="classInfo.lastName == null || classInfo.firstName == null">Класс без учителя</div>
                              <div class="advice is-register" *ngIf="classInfo?.isActivated">Регистрация пройдена</div>
                              <div class="student-online-status not-register" *ngIf="!classInfo?.isActivated">Не зарегистрирован</div>
                            </div>
                          </div>
                        </a>

                        <div class="class-column _6">
                          <a class="class-test-row classes-link">
                            <div class="class-test-column test">
                              <div class="length-students">{{ classInfo.totalPupilsCount }}</div>
                            </div>
                            <div *ngIf="isTestStatisticsShown" class="class-test-column test">
                              <div class="length-students-complete">
                                {{ classInfo.completedTestsCount }}
                              </div>
                            </div>
                          </a>
                        </div>

                        <div class="class-column _11 class-column_action dropdown-wrapper">
                          <button class="action_button" (click)="showActionWithClass($event)">
                            <span></span>
                          </button>

                          <div class="action_box">
                            <div class="action_box-wrapper">
                              <a class="action-button" (click)="showEditClass(classInfo)">Редактировать</a>
                              <a class="action-button" (click)="selectSchoolClassToDelete(classInfo, $event)"> Удалить </a>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="class-line"
                          [ngClass]="teachers.length === i ? 'no-underline' : ''"
                        ></div> -->
                      </div>
                    </div>
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="editClass">
  <prf-schooladmin-edit-class (showEditClass)="hideEditClass($event)" [teacher]="selectedTeacher" [teacherClass]="selectedClass">
  </prf-schooladmin-edit-class>
</ng-container>

<!--Welcome-->
<ng-template #welcome>
  <ng-container *ngIf="userRole === 'schooladmin' || 'teacher'">
    <prf-schooladmin-welcome></prf-schooladmin-welcome>
  </ng-container>
</ng-template>

<!-- pop-up -->

<ng-container *ngIf="showDeletePopUp">
  <div class="popup-overlay overlay">
    <div class="popup-container" #popup>
      <div class="close" (click)="closeDeletePopUp()"></div>
      <div class="popup-title">Удалить {{ classSelectedToDelete.number }} {{ classSelectedToDelete.letter }}?</div>
      <div class="buttons">
        <a class="button w-button popup-button popup-button_confirm" (click)="deleteSchoolClass(classSelectedToDelete.id)"> Да </a>
        <a class="button w-button popup-button popup-button_cancel" (click)="closeDeletePopUp()"> Нет </a>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #tooltip let-classInfo="data">
  <prf-tooltip>{{ classInfo.number }} «{{ classInfo.letter }}»</prf-tooltip>
</ng-template>
