<label>{{ controlConfig().label }}</label>
<div class="wrapper">
  @for (option of controlConfig().toggleOptions; track option.value) {
    <button
      type="button"
      class="option-button"
      [class.chosen]="option.value === formControlManager().value"
      (click)="onClick(option.value)"
    >
      {{ option.label }}
    </button>
  }
</div>
