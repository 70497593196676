import { inject, Injectable, InputSignal } from '@angular/core';

import { UserDataHandlerService } from '@profilum-library';
import { AirDatepickerOptions } from 'air-datepicker';

import { DatepickerFormControlInterface } from '../interfaces/datepicker.interface';
import { DATE_PICKER_CONFIG } from '../config/datepicker.config';

@Injectable()
export class DatepickerService {
  private userDataHandlerService = inject(UserDataHandlerService);

  public getConfig(datepickerControl: InputSignal<DatepickerFormControlInterface>): AirDatepickerOptions | null {
    if (datepickerControl()?.datePickerConfig && datepickerControl().datePickerConfig.appliedBy === 'role') {
      return datepickerControl().datePickerConfig.targets.includes(this.userDataHandlerService.getUserInfo().role)
        ? DATE_PICKER_CONFIG[datepickerControl().datePickerConfig.configName]
        : null;
    }
  }

  public getLabel(datepickerControl: InputSignal<DatepickerFormControlInterface>): string {
    if (datepickerControl()?.datePickerConfig && datepickerControl().datePickerConfig.appliedBy === 'role') {
      return datepickerControl().datePickerConfig.targets.includes(this.userDataHandlerService.getUserInfo().role)
        ? datepickerControl().datePickerConfig.modifiedLabel
        : datepickerControl().label;
    }
  }
}
