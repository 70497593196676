<div class="checkbox-wrapper">
  <input
    type="checkbox"
    class="checkbox"
    [formControl]="formControlManager"
    (change)="onCheckboxChange()"
    [class.error-field]="!formControlManager.value && formControlManager.touched"
  />
  <label for="{{ controlConfig.name }}" [class.error-label]="!formControlManager.value && formControlManager.touched">{{
    controlConfig.label
  }}</label>
</div>
