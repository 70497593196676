import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from 'app/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import {
  AnimationsService,
  AppsettingsModule,
  AppSettingsService,
  AuthGuard,
  CanLoadPupil,
  ErrorHandlerService,
  MainGuard,
  ProfilumLibraryModule,
} from '@profilum-library';
import { LandingModule } from './landing/landing.module';
import { PagesModule } from './pages/pages.module';
import { routeConfig } from './router.config';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SideModalComponent } from './ui-kit/components/modal/side-modal';
import { CenterModalComponent } from './ui-kit/components/modal/center-modal';
import { ProfilumComponentsModule } from '../../../../libs/profilum-components/src/profilum-components.module';
import { EurochemModule } from './landing/eurochem/eurochem.module';

registerLocaleData(localeRu);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    PagesModule,
    LandingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SideModalComponent,
    CenterModalComponent,
    ProfilumLibraryModule,
    RouterModule.forRoot(routeConfig, {}),
    NgCircleProgressModule.forRoot(),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
    }),
    ProfilumComponentsModule,
    EurochemModule,
  ],
  providers: [
    CookieService,
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    AnimationsService,
    AppSettingsService,
    AppsettingsModule.init(),
    MainGuard,
    AuthGuard,
    CanLoadPupil,
    ErrorHandlerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
