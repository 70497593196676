<div class="prf-restore-password-page">
  <div *ngIf="isKruzhkiPage" class="partners-logo hide-mobile">
    <a class="logo" href="https://www.mos.ru/" target="_blank">
      <img src="./assets/images/registration/depkult_gerb.svg" alt="logo" />
    </a>
    <a class="logo" href="https://mos-razvitie.ru/" target="_blank">
      <img src="./assets/images/registration/mosrazvitie.svg" alt="logo" />
    </a>
  </div>
  <div class="prf-restore-password" [class.phonecall-form]="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_FORM">
    <form [formGroup]="formGroup" autocomplete="off">
      <section class="login-form-row prf-restore-password-form-row" *ngIf="currentStep === RestorePasswordStepsEnum.LOGIN_INPUT">
        <h1 class="prf-restore-password-header">Забыли пароль?</h1>
        <div class="prf-restore-password-label">
          Введите {{ isKruzhkiPage ? 'почту' : 'телефон или почту' }}, на которую<br />
          зарегистрирован аккаунт, чтобы создать<br />
          новый пароль
        </div>
        <div class="login-field-column prf-restore-password-column">
          <prf-dynamic-label [prfFormControl]="formControls.username" [prfFormControlElement]="username">
            {{ isKruzhkiPage ? ('SHARED.FORM.EMAIL' | translate) : ('SHARED.FORM.PHONE_OR_EMAIL' | translate) }}
          </prf-dynamic-label>
          <input
            type="text"
            autocomplete="off"
            class="prf-form-input prf-restore-password-form-input"
            maxlength="70"
            name="username"
            data-name="username"
            [class.error-field]="isLoginInvalid"
            id="username"
            formControlName="username"
            (focusin)="formControls.username['focused'] = true"
            (input)="checkLogin()"
            #username
          />
          <ng-container *ngIf="formControls.username.dirty">
            <mat-error class="prf-login-error" *ngIf="formControls.username.errors?.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
            <mat-error class="prf-login-error" *ngIf="formControls.username.errors?.pattern"> Проверьте написание </mat-error>
            <mat-error class="prf-login-error" *ngIf="formControls.username.errors?.isNotUsed">
              {{ 'SHARED.FORM.PHONE_IS_NOT_REGISTERED' | translate }}
            </mat-error>
          </ng-container>
          <prf-reset-button [prfFormControl]="formControls.username" [prfFormControlElement]="username"></prf-reset-button>
        </div>
      </section>
      <section class="prf-restore-password-form-row" *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_SMS">
        <ng-container *ngIf="!errorResetPassword; else errorResetPasswordTemplate">
          <ng-container *ngIf="usernameType === 'phone'">
            <ng-container *ngIf="!failedLimitSms">
              <img class="prf-restore-password-icon" src="./profilum-assets/images/registration/success.svg" alt="Иконка сброса пароля" />
              <h1 class="prf-restore-password-header">Сообщение отправлено</h1>
              <label for="username" class="prf-restore-password-label">
                Если в течение 10 минут ничего не пришло — обратитесь в поддержку
              </label>
            </ng-container>
            <ng-container *ngIf="failedLimitSms">
              <img class="prf-restore-password-icon" src="./profilum-assets/images/registration/success.svg" alt="Иконка сброса пароля" />
              <h1 class="prf-restore-password-header">Не получается войти?</h1>
              <label for="username" class="prf-restore-password-label">
                Напишите в поддержку, чтобы специалист помог восстановить доступ
              </label>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="usernameType === 'email'">
            <img class="prf-restore-password-icon" src="./profilum-assets/images/registration/success.svg" alt="Иконка сброса пароля" />
            <h1 class="prf-restore-password-header">Если email верный, на него придет письмо для восстановления пароля.</h1>
            <label for="username" class="prf-restore-password-label"> Если возникли трудности, напишите в поддержку. </label>
          </ng-container>
        </ng-container>
        <ng-template #errorResetPasswordTemplate>
          <img
            class="prf-restore-password-icon"
            src="./profilum-assets/images/registration/error-reset-password.svg"
            alt="Иконка сброса пароля"
          />
          <h1 class="prf-restore-password-header">Что-то пошло не так. Мы уже работаем над этой проблемой и скоро всё наладим.</h1>
          <label for="username" class="prf-restore-password-label">
            Пожалуйста, зайдите на платформу позже. Скоро всё снова заработает, как надо!
          </label>
        </ng-template>
      </section>
      <section
        class="login-form-row prf-restore-password-form-row"
        *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_INFO_PAGE"
      >
        <h1 class="prf-restore-password-header">Восстановление пароля по номеру телефона:</h1>
        <ol class="prf-restore-password-steps">
          <li>Закажите звонок</li>
          <li>В течение нескольких минут на ваш номер позвонит бот, не берите трубку</li>
          <li>Укажите последние 4 цифры номера этого входящего вызова для сброса пароля</li>
        </ol>
      </section>
      <section
        class="login-form-row prf-restore-password-form-row"
        *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_FORM"
      >
        <h1 class="prf-restore-password-header">Укажите последние 4 цифры номера входящего вызова</h1>
        <div class="prf-restore-password-label">
          Звонок поступит на номер <span class="prf-restore-password-label__phone">{{ userPhoneWithSeparators }}</span
          >. Если звонка нет больше трех минут — напишите в поддержку
        </div>
        <div class="code-input__wrapper">
          <prf-code-input
            #codeInput
            [codeLength]="4"
            [currentState]="codeInputFieldState"
            (codeCompleted)="validateInputCode($event)"
            (codeChanged)="onCodeInputChanged()"
          ></prf-code-input>
          <p *ngIf="codeInputFieldState === CodeInputStateEnum.ERROR" class="code-input__state-message error-text">
            {{ RestorePasswordByPhoneCallTextsEnum.ERROR }}
          </p>
          <p *ngIf="codeInputFieldState === CodeInputStateEnum.DISABLED" class="code-input__state-message disabled-text">
            {{ RestorePasswordByPhoneCallTextsEnum.EXCEED_ATTEMPTS }}
          </p>
        </div>
      </section>
      <section
        class="login-form-row prf-restore-password-form-row"
        *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE"
      >
        <svg class="tech-error-icon" xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
          <rect x="0.570312" width="40" height="40" rx="20" fill="#F05F4C" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.0703 23.3286L27.1561 29.4144L29.9845 26.5859L23.8987 20.5002L29.9845 14.4144L27.1561 11.5859L21.0703 17.6717L14.9845 11.5859L12.1561 14.4144L18.2419 20.5002L12.1561 26.5859L14.9845 29.4144L21.0703 23.3286Z"
            fill="white"
          />
        </svg>
        <h1 class="prf-restore-password-header">Техническая ошибка, повторите попытку позже</h1>
        <div class="prf-restore-password-label">
          К сожалению, сейчас мы не можем прислать вам новый пароль. Повторите попытку <br />
          через 1 час.
        </div>
      </section>
    </form>
    <div class="prf-restore-password-frame" *ngIf="currentStep === RestorePasswordStepsEnum.LOGIN_INPUT">
      <button
        class="prf-btn-primary"
        prfYmReachGoal
        [ymItemTarget]="ymItems.Recovery_Password_Next"
        [class.prf-btn-primary_disabled]="!formControls.username.valid"
        (click)="next()"
      >
        Отправить
      </button>
      <button class="prf-btn-link" (click)="back()">Вернуться на вход</button>
    </div>
    <div class="prf-restore-password-frame" *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_SMS">
      <ng-container *ngIf="!errorResetPassword">
        <ng-container *ngIf="failedLimitSms || usernameType === 'email'; else successPhoneTemplate">
          <ng-container *ngIf="!isKruzhkiPage; else kruzhkiPageButtons">
            <button class="prf-btn-primary prf-restore-password-frame-button" [routerLink]="['/']">Вернуться на вход</button>
            <!--            <button class="prf-btn-link prf-restore-password-frame-support" (click)="openWidget($event)">Обратиться в поддержку</button>-->
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="errorResetPassword">
        <button class="prf-restore-password-frame-button prf-btn-primary" [routerLink]="['/']">Вернуться на вход</button>
      </ng-container>
    </div>
    <div class="prf-restore-password-frame" *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_INFO_PAGE">
      <button class="prf-btn-primary" (click)="requestCall()">Заказать звонок</button>
      <button class="prf-btn-link" (click)="back()">Вернуться на вход</button>
    </div>
    <div class="prf-restore-password-frame" *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_FORM">
      <button
        *ngIf="codeInputFieldState !== CodeInputStateEnum.DISABLED"
        class="prf-btn-primary"
        [ngClass]="{ 'prf-btn-primary_disabled prf-btn-primary_disabled_no-click': nextAttemptPhoneCallTimer$ | async }"
        (click)="requestCall(true)"
      >
        Запросить новый звонок&nbsp;<span *ngIf="nextAttemptPhoneCallTimer$ | async">{{
          nextAttemptPhoneCallTimer$ | async | date: 'mm:ss'
        }}</span>
      </button>
      <button *ngIf="codeInputFieldState === CodeInputStateEnum.DISABLED" class="prf-btn-primary" (click)="navigateToInputLoginStep()">
        Восстановить пароль через e-mail
      </button>
      <!--      <button class="prf-btn-link" (click)="openWidget($event)">Написать в поддержку</button>-->
    </div>
    <div class="prf-restore-password-frame" *ngIf="currentStep === RestorePasswordStepsEnum.RESET_PASSWORD_BY_PHONE_CALL_ERROR_PAGE">
      <button class="prf-btn-primary" (click)="navigateToLogin()">Вернуться на вход</button>
    </div>
  </div>
  <span *ngIf="isKruzhkiPage" class="prf-restore-password-buttons-footer">
    Проводится на базе платформы
    <a [routerLink]="['/']" target="_blank">
      <img src="./profilum-assets/images/registration/profilum_logo_small.svg" alt="PROFILUM" />
    </a>
  </span>
  <prf-page-footer class="prf-footer"></prf-page-footer>
</div>
<ng-template #successPhoneTemplate>
  <button type="button" class="prf-btn-primary prf-restore-password-frame-button" [routerLink]="['/']">Продолжить</button>
  <button type="button" class="prf-btn-link prf-restore-password-frame-support" (click)="openWidget($event)">Возникла проблема</button>
</ng-template>
<ng-template #kruzhkiPageButtons>
  <button class="prf-btn-primary" [routerLink]="['/']">Продолжить</button>
</ng-template>
