export const MangoCallSiteScript = `
<script>
    var button = document.createElement("button");
        button.textContent = "Позвонить онлайн";
        button.setAttribute("style", "border-radius: 24px; background: #08b8e8; width: 175px;height: 36px;white-space: nowrap;border: none; font-family: 'SegoeUISemibold'; color: #fff;font-size: 13px;line-height: 36px;cursor: pointer;position: fixed;right: 125px;bottom: 38px;text-align: center;"),
        button.setAttribute("class", "mango-call-site"),
        button.setAttribute("onClick", "window.open('https://widgets.mango-office.ru/widget/call-from-site-auto-dial/MTAwMTkzMDE=', '_blank', 'width=238,height=400,resizable=no,toolbar=no,menubar=no,location=no,status=no'); return false;")
        document.body.appendChild(button);

    var a = document.createElement("link");
        a.setAttribute("rel", "stylesheet");
        a.setAttribute("type", "text/css");
        a.setAttribute("href", "https://widgets.mango-office.ru/css/widget-button.css");
        a.onerror = function(){
            document.querySelector(".mango-call-site").setAttribute("onClick","alert('В данный момент наблюдаются технические проблемы и совершение звонка невозможно');")
        };
        document.documentElement.firstChild.appendChild(a)
</script>
<style>
    @media (max-width: 1650px) {
        .mango-call-site{
            bottom: 0 !important;
            right: 0 !important;
            transform: translateX(calc(100% + 145px)) translateY(0%);
            transform-origin: bottom right;
            rotate: -90deg;
       }
    }
</style>`;
