import { AirDatepickerOptions } from 'air-datepicker';
import moment from 'moment';

export const DATE_PICKER_CONFIG: Record<string, AirDatepickerOptions> = {
  BASE: {
    autoClose: true,
    minDate: '01.01.1900',
    maxDate: new Date(),
    multipleDates: false,
    toggleSelected: false,
  },
  PUPILS_BIRTHDAY: {
    minDate: new Date(moment().subtract(19, 'year').add(1, 'days').toString()),
    maxDate: new Date(moment().subtract(13, 'year').subtract(1, 'days').toString()),
  },
};
