import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CatalogModule } from './catalog/catalog.module';
import { ControlPanelModule } from './control-panel/control-panel.module';
import { ControlPanelRoutes } from './control-panel/control-panel.routing';
import { EventsModule } from './events-page/events.module';
import { NewsModule } from './news-page/news.module';
import { Page404Module } from './page-404/page-404.module';
import { Page500Module } from './page-500/page-500.module';
import { PagesComponent } from './pages.component';
import { routeConfig } from './pages.router.config';
import { PlayerModule } from './player/player.module';
import { ResultsModule } from './results/results.module';
import { TaskPageComponent } from './task-page/task-page.component';
import { CanLoadPupil, StartMincifryTestGuard } from '@profilum-library';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { ChooseProfessionModule } from './choose-profession/choose-profession.module';
import { UtilsService } from '../ui-kit/services/utils-services/utils.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule.forChild(routeConfig),
    // RouterModule.forRoot(ControlPanelRoutes, {}),
    SharedModule,
    PlayerModule,
    ResultsModule,
    ControlPanelModule,
    CatalogModule,
    EventsModule,
    NewsModule,
    Page500Module,
    Page404Module,
    ChooseProfessionModule,
  ],
  declarations: [PagesComponent, TaskPageComponent],
  exports: [PagesComponent],
  providers: [CanLoadPupil, StartMincifryTestGuard, GoalsService, UtilsService],
})
export class PagesModule {}
