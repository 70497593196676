import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiCoursesMaterialsService,
  ApiUsersService,
  AppSettingsService,
  AuthHandlerService,
  B2gSaasService,
  WebStorageService,
} from '@profilum-library';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { LoginBase } from 'app/shared/auth-classes/login.base';

@Component({
  selector: 'prf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends LoginBase<B2gSaasService> {
  public isVGuser: boolean = false;
  public urlToken: string = '';
  public showCaptchaFrame: boolean = false;
  public isValidCaptcha: boolean = false;
  public attemptsToLogin: number = 0;

  constructor(
    meta: Meta,
    translateService: TranslateService,
    overlayService: OverlayBusyService,
    loginService: B2gSaasService,
    b2gSaasService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    appSettings: AppSettingsService,
    @Inject(DOCUMENT) document: Document,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    apiCoursesMaterialsService: ApiCoursesMaterialsService,
    apiUsersService: ApiUsersService,
    authHandlerService: AuthHandlerService,
  ) {
    super(
      meta,
      translateService,
      overlayService,
      loginService,
      b2gSaasService,
      router,
      fb,
      document,
      webStorageService,
      userStorageService,
      appSettings,
      apiCoursesMaterialsService,
      apiUsersService,
      authHandlerService,
    );
  }

  public showPassword(event: any): void {
    const passInput = event.target.previousSibling;
    const toogle = event.target;

    if (passInput.type === 'password') {
      passInput.type = 'text';
      toogle.classList.remove('eye');
      toogle.classList.add('eye__closed');
    } else {
      passInput.type = 'password';
      toogle.classList.remove('eye__closed');
      toogle.classList.add('eye');
    }
  }

  public openWidget(event: Event): void {
    event?.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    (document.querySelector('.online-chat-root-TalkMe #supportTrigger') as HTMLElement | null)?.click();
  }

  public isAttemptsExceeded(): boolean {
    return this.attemptsToLogin >= 4;
  }

  public showProfilumLogo(): boolean {
    return !this.isKruzhkiPage;
  }

  public onClickLogin(): void {
    if (this.isAttemptsExceeded()) {
      return;
    }

    if (!this.isAccessDenied) {
      this.attemptsToLogin += 1;
    }

    this.showCaptchaFrame = this.isAttemptsExceeded();
    this.isValidCaptcha = !this.isAttemptsExceeded();
    super.onClickLogin();
  }

  public continueToLogin(event: boolean): void {
    this.isValidCaptcha = event;
    if (this.isValidCaptcha) {
      this.attemptsToLogin = 0;
      this.showCaptchaFrame = false;
    }
  }
}
