import { AbstractControl, ValidationErrors } from '@angular/forms';
import { REG_EXP } from '@profilum-collections/reg-exp';

export function passwordValidator(control: AbstractControl): ValidationErrors | null {
  const testOneLetter: RegExp = REG_EXP.testOneLetter;
  const testOneCapitalLetter: RegExp = REG_EXP.testOneCapitalLetter;
  const testOneDigit: RegExp = REG_EXP.testOneDigit;
  const testSixCharter: RegExp = REG_EXP.testSixCharter;

  if (control.value) {
    if (!testOneCapitalLetter.test(control.value) || !testOneLetter.test(control.value)) {
      return { letterError: true };
    }
    if (!testOneDigit.test(control.value)) {
      return { numberError: true };
    }
    if (!testSixCharter.test(control.value)) {
      return { lengthError: true };
    }
  }
}
