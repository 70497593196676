<div class="pupil-menu-bottom">
  <div class="overlay" [class.overlay_on]="onbording"></div>
  <div class="pupil-menu-bottom-item" [class.pupil-menu-bottom-item_active]="root === 'pupil'">
    <div class="pupil-menu-bottom-item-icon" (click)="selectLessons()">
      <div class="icon icon-course" [ngClass]="{ 'icon-course-active': root === 'pupil' }"></div>
    </div>
    <div class="pupil-text" [ngClass]="{ 'pupil-text-active': root === 'pupil' }">Профпробы</div>
  </div>
  <div class="pupil-menu-bottom-item" [class.pupil-menu-bottom-item_active]="root === 'profile'" (click)="selectMain()">
    <div class="pupil-menu-bottom-item-icon">
      <div class="icon icon-user" [ngClass]="{ 'icon-user-active': root === 'profile' }"></div>
    </div>
    <div class="pupil-text" [ngClass]="{ 'pupil-text-active': root === 'profile' }">Профиль</div>
  </div>
</div>
