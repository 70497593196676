import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForOf, NgIf } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { RamStorageService, StorageKeys } from '@profilum-library';
import { TippyDirective } from '@ngneat/helipopper';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';

import { IChildInfo } from '../parent.interface';

@Component({
  selector: 'prf-children-tabs',
  templateUrl: './children-tabs.component.html',
  styleUrls: ['./children-tabs.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, NgForOf, TippyDirective, TooltipComponent],
})
export class ChildrenTabsComponent extends UnsubscribeComponent {
  @ViewChild('tabDropdown') tabDropdown: ElementRef;
  public childrenMax: number = 5;
  public selectedChild: IChildInfo = null;
  public buttonOpacity: number = 0;
  public isTabDropdown: boolean = false;
  public isMobile: boolean = false;

  private _children: Array<IChildInfo> = [];
  private _childId: string = '';

  @Output() addChild = new EventEmitter<boolean>();
  @Output() selectChild = new EventEmitter<IChildInfo>();
  @Input() set children(val: Array<IChildInfo>) {
    this._children = val;

    if (this._children.length > 0) {
      if (this._childId) {
        const children = this._children.filter(c => c.userId === this._childId);

        if (children && children.length) {
          this.onSelectChild(children[0]);
        } else {
          this.onSelectChild(this._children[0]);
        }
      } else {
        this.onSelectChild(this._children[0]);
      }
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private ramStorageService: RamStorageService,
    private breakpointsService: BreakpointsService,
  ) {
    super();
    this.activatedRoute.params.pipe(this.unsubscribeOperator).subscribe((params: Params) => {
      if (params && params['uid']) {
        this._childId = params['uid'];
      }
    });
    this.breakpointsService.isMobile.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => (this.isMobile = isMobile));
  }

  get children(): Array<IChildInfo> {
    return this._children;
  }

  public onSelectChild(child: IChildInfo): void {
    this.selectedChild = child;
    this.selectChild.emit(this.selectedChild);
    this.ramStorageService.set(StorageKeys.CurrentChild, child);
    this.isTabDropdown = false;
  }

  public clickAddChild(): void {
    this.addChild.emit(true);
    this.isTabDropdown = false;
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target): void {
    if (this.tabDropdown && !this.tabDropdown?.nativeElement.contains(target)) {
      this.isTabDropdown = false;
    }
  }
}
