import { ChangeDetectorRef, Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, take, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { CodeInputStateEnum } from '@profilum-components/code-input/code-input.config';
import { CodeInputComponent } from '@profilum-components/code-input/code-input.component';
import { ButtonType } from '@profilum-collections/common.collections';
import {
  B2gEurochemService,
  Helper,
  ICheckReferralResponse,
  ICodeSendResponse,
  ICodeVerifyResponse,
  IInviteResponse,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';

import { FormGeneratorComponent } from 'app/shared/common-components/form-generator/form-generator.component';
import { EurochemService } from 'app/shared/services/eurochem.service';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { EUROCHEM_DICTIONARY } from '../dictionary';
import { SendCodeErrorsEnum } from '../eurochem.model';

@Component({
  selector: 'prf-registration-parent',
  templateUrl: './registration-parent.component.html',
  styleUrl: './registration-parent.component.scss',
  providers: [B2gEurochemService],
})
export class RegistrationParentComponent extends UnsubscribeComponent implements OnInit {
  public guId: string;
  public codeInputFieldState: CodeInputStateEnum = CodeInputStateEnum.DEFAULT;
  public formConfig = 'registration-parent.form.json';
  public confirmButtonText: string = 'Получить код';

  public isVisibleCode: boolean = false;
  public isReferralValid: boolean;

  private timerSubscription: Subscription;
  private NEXT_ATTEMPT_SECONDS: number = 180;
  public secondsToNextCallRequest: number = this.NEXT_ATTEMPT_SECONDS;

  @ViewChild('parentRegistrationForm') public parentRegistrationForm: FormGeneratorComponent;
  @ViewChild('codeInput') public codeInput!: CodeInputComponent;

  private b2gEurochemService = inject(B2gEurochemService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private eurochemService = inject(EurochemService);
  private webStorageService = inject(WebStorageService);

  constructor(private route: ActivatedRoute) {
    super();
  }

  public ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.guId = params.get('hrid');
      this.eurochemService
        .checkReferral(this.guId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: ICheckReferralResponse) => {
          this.isReferralValid = !(response.status !== 'Success' || !response.isValid);
          this.changeDetectorRef.detectChanges();
        });
    });
  }

  public sendCode(): void {
    const phone: string = this.convertPhoneNumber(this.parentRegistrationForm.formValue.phone);

    this.b2gEurochemService
      .sendCode(this.guId, phone)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: ICodeSendResponse) => {
        if (response.status === 'Success') {
          this.isVisibleCode = true;
          this.startPhoneCallTimer();
        }
      });
  }

  public verifyInputCode(code: string): void {
    this.b2gEurochemService
      .verifyCode(this.guId, code)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response: ICodeVerifyResponse) => {
          if (response.status === 'Failed') {
            this.codeInput.reset();
            this.codeInputFieldState = CodeInputStateEnum.ERROR;
            this.changeDetectorRef.detectChanges();
          } else if (response.status === 'Success') {
            this.timerSubscription.unsubscribe();
            this.getInviteLink();
          }
        },
        error: (): void => {
          this.codeInput.reset();
          this.codeInputFieldState = CodeInputStateEnum.ERROR;
        },
      });
  }

  private getInviteLink(): void {
    this.b2gEurochemService
      .invite(this.guId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: IInviteResponse) => {
        if (response.status === 'Success' && response.invitationLink) {
          window.open(response.invitationLink, '_self');
          this.webStorageService.set(StorageKeys.ParentConsent, true);
        }
      });
  }

  private startPhoneCallTimer(): void {
    this.timerSubscription = timer(0, 1000)
      .pipe(take(this.NEXT_ATTEMPT_SECONDS), takeUntil(this.unsubscribe))
      .subscribe(passedSeconds => {
        this.secondsToNextCallRequest = (this.NEXT_ATTEMPT_SECONDS - passedSeconds - 1) * 1000;
        this.changeDetectorRef.detectChanges();
      });
  }

  private convertPhoneNumber(phoneNumber: string): string {
    if (REG_EXP.phoneRegExp.test(phoneNumber)) {
      phoneNumber = phoneNumber?.replace(/\D/g, '');
      if (phoneNumber) {
        phoneNumber = Helper.phoneValidation(phoneNumber);
      }
    }
    return phoneNumber;
  }

  protected readonly CodeInputStateEnum = CodeInputStateEnum;
  protected readonly SendCodeErrorsEnum = SendCodeErrorsEnum;
  protected readonly ButtonType = ButtonType;
  protected readonly EUROCHEM_DICTIONARY = EUROCHEM_DICTIONARY;
}
