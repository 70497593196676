import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { CodeInputComponent } from '@profilum-components/code-input/code-input.component';
import { ButtonComponent } from '@profilum-components/button/button.component';

import { SharedModule } from 'app/shared/shared.module';
import { FormGeneratorComponent } from 'app/shared/common-components/form-generator/form-generator.component';
import { CaptchaModule } from 'app/shared/common-components/captcha/captcha.module';
import { DynamicLabelModule } from 'app/shared/common-components/prf-form/dynamic-label/dynamic-label.module';
import { RegistrationParentComponent } from './registration-parent/registration-parent.component';
import { RegistrationChildComponent } from './registration-child/registration-child.component';
import { EurochemRoutingModule } from './eurochem.routing-module';
import { LandingEurochemComponent } from './landing-eurochem/landing-eurochem.component';

@NgModule({
  declarations: [RegistrationParentComponent, RegistrationChildComponent, LandingEurochemComponent],
  imports: [
    CommonModule,
    EurochemRoutingModule,
    SharedModule,
    FormGeneratorComponent,
    CaptchaModule,
    DynamicLabelModule,
    CodeInputComponent,
    ButtonComponent,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  exports: [LandingEurochemComponent],
})
export class EurochemModule {}
