import { Component, Input } from '@angular/core';
import { FormsModule, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'prf-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  standalone: true,
  imports: [FormsModule],
})
export class FormErrorsComponent {
  @Input()
  public set errors(errors: ValidationErrors) {
    const error: string[] = errors ? Object.keys(errors) : null;
    this.errorText = error ? this.errorsEnum[error?.[0]] : '';
  }

  public errorText: string;

  private readonly errorsEnum = {
    incorrectPhone: 'Номер телефона введен неверно',
    incorrectEmail: 'Проверьте правильность введенных данных',
    required: 'Обязательное поле',
    requiredTrue: 'Обязательное поле',
    letterError: 'Строчные и заглавные латинские буквы',
    numberError: '1 цифра',
    lengthError: 'от 6 символов',
    mismatch: 'Пароли не совпадают',
  };
}
