import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { JsonFormData, JsonInitialField } from '../interfaces/form.interface';
import { DropdownJsonFormControl } from '../components/inputs/dropdown/interfaces/dropdown.interfaces';
import { phoneValidator } from '../validators/phone-validator';
import { emailValidator } from '../validators/email-validator';
import { passwordValidator } from '../validators/password-validator';
import { identicalInputsValidator } from '../validators/identical-inputs-validator';

@Injectable({
  providedIn: 'root',
})
export class FormGeneratorService {
  private basePath: string = 'assets/forms-config/';
  private http = inject(HttpClient);
  private readonly currentTemplate: BehaviorSubject<string>;

  constructor() {
    this.currentTemplate = new BehaviorSubject('defaultTemplate');
  }

  public getFormData(url: string): Observable<JsonFormData> {
    return url ? this.http.get<JsonFormData>(this.basePath + url) : throwError((): string => 'Не задан конфигурационный файл формы');
  }

  public setInitialFormValues(config: any, valuesObject: any): any {
    config.controls.forEach((item: JsonInitialField) => {
      if (valuesObject.hasOwnProperty(item.name)) {
        if (typeof valuesObject[item.name] !== 'string') {
          item.initialValue = '';
          this.insertAdditionalFields(item, valuesObject[item.name]);
        } else {
          item.initialValue = valuesObject[item.name];
        }
      }
    });
    return config;
  }

  private insertAdditionalFields(item: JsonInitialField, itemValuesObject: any): void {
    if (['dropdown', 'dropdown-search'].includes(item.type) && itemValuesObject?.dropdownOptions.length) {
      (item as DropdownJsonFormControl).dropdownOptions = [...itemValuesObject.dropdownOptions];
    }
  }

  public setNewTemplate(templateName: string): void {
    this.currentTemplate.next(templateName);
  }

  public getCurrentTemplate(): BehaviorSubject<string> {
    return this.currentTemplate;
  }

  public setValidatorsByType(control): ValidationErrors | null {
    let validator: ValidationErrors = null;

    switch (control.type) {
      case 'phone': {
        validator = phoneValidator;
        break;
      }
      case 'email': {
        validator = emailValidator;
        break;
      }
      default:
        break;
    }
    return validator;
  }

  public setCustomValidator(control, validator: string): ValidationErrors | null {
    let validatorToAdd: ValidationErrors = null;

    switch (validator) {
      case 'checkNewPassword': {
        validatorToAdd = passwordValidator;
        break;
      }

      default:
        break;
    }
    return validatorToAdd;
  }

  public getCustomFormValidators(formConfig: JsonFormData, form: FormGroup): ValidatorFn[] {
    const customFormValidators: ValidatorFn[] = [];

    formConfig.customFormValidators.forEach(customValidatorConfig => {
      switch (customValidatorConfig.validatorKey) {
        case 'identicalInputsValidator':
          customFormValidators.push(identicalInputsValidator(form, customValidatorConfig.controls));
          break;
        default:
          break;
      }
    });

    return customFormValidators;
  }
}
