import { AbstractControl, ValidationErrors } from '@angular/forms';

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) return null;

  const phone: string = control.value.replace(/\D/g, '');

  if (!enabledPhoneCodes.includes(phone[1]) || phone.length !== 11) {
    return { incorrectPhone: true };
  }

  const phoneNumberRegex: RegExp = /^(\+7|7|8)?[\s-]?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
  return phoneNumberRegex.test(control.value) ? null : { incorrectPhone: true };
}

const enabledPhoneCodes: string[] = ['6', '7', '9'];
