import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import AirDatepicker from 'air-datepicker';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { DATE_PICKER_CONFIG } from './config/datepicker.config';
import { DatepickerFormControlInterface } from './interfaces/datepicker.interface';
import { DatepickerService } from './services/datepicker.service';

@Component({
  selector: 'prf-datepicker',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [DatepickerService],
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent extends UnsubscribeComponent implements OnInit, AfterViewInit {
  public isMobile = signal<boolean>(false);

  public control = input<DatepickerFormControlInterface>();
  public formControlManager = input<FormControl>();

  private breakpointsService = inject(BreakpointsService);
  private datepickerService = inject(DatepickerService);

  public ngOnInit(): void {
    this.breakpointsService.isMobile.pipe(takeUntil(this.unsubscribe)).subscribe(isMobile => this.isMobile.set(isMobile));
  }

  public ngAfterViewInit(): void {
    new AirDatepicker(`#${this.control().name}`, {
      ...DATE_PICKER_CONFIG.BASE,
      ...this.datepickerService.getConfig(this.control),
      selectedDates: this.formControlManager().value ? [this.formControlManager().value] : false,
      isMobile: this.isMobile(),
      onSelect: ({ date, formattedDate }): void => this.formControlManager().setValue(formattedDate),
    });
  }

  public get label(): string {
    return this.datepickerService.getLabel(this.control);
  }
}
