import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BvbModule } from 'app/landing/bvb/bvb.module';
import { SharedModule } from '../shared/shared.module';
import { LandingBaseModule } from './base/landing-base.module';
import { landingRoutes } from './landing-routing.config';
import { LandingComponent } from './landing/landing.component';
import { LandingMosoblModule } from './mosobl/landing-mosobl.module';
import { MosruModule } from './mosru/mosru.module';
import { ProsvetAuthModule } from './prosvet-auth/prosvet-auth.module';
import { JwtModule } from './jwt/jwt.module';
import { MurmanskModule } from './pilot-regions/murmansk/murmansk.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(landingRoutes),
    LandingBaseModule,
    LandingMosoblModule,
    ProsvetAuthModule,
    MosruModule,
    SharedModule,
    BvbModule,
    JwtModule,
    MurmanskModule,
  ],
  declarations: [LandingComponent],
})
export class LandingModule {}
