import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { IResult, IResultsData } from 'app/pages/results/results.interface';
import { Gender } from 'app/shared/enums/gender.enums';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import {
  ApiPlayerService,
  ApiResultsService,
  IGetDataForResultsPageResponse,
  ITestInfo,
  IUserInfo,
  IUserProfileInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IPupilInHistory, ITestsResults, ITestsResultsView } from './pupil-history.interface';

export enum UserRoles {
  pupil = 'pupil',
  parent = 'parent',
  tutor = 'tutor',
}

@Component({
  selector: 'prf-pupil-tests-history',
  templateUrl: './pupil-tests-history.component.html',
  styleUrls: ['./pupil-tests-history.component.scss'],
})
export class PupilTestsHistoryComponent extends UnsubscribeComponent {
  public pupilInfo: IPupilInHistory;
  public userData: IUserInfo;
  public userInfo: IUserProfileInfo;
  public _sessionList: IHistory[];
  public sessionIdsList: string[] = [];
  public sessionListView: any[] = [];
  public testsResultsView: ITestsResultsView[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public isLoaded: boolean = false;
  public isPupilPage: boolean = true;
  public testVariantsMotivationIds: string = TESTS_VARIANTS_IDS.motivationtest;
  public isPupilLoaded: boolean = false;
  public sessionStatus: string = '';
  public testSessionId: string = '';
  public uncompletedTest: boolean = false;
  private testInfo: ITestInfo;

  @Input() public pupil: IPupilInHistory;
  @Output() public testsResultsViewEmitter: EventEmitter<ITestsResultsView[]> = new EventEmitter<ITestsResultsView[]>();
  @Input() private set sessionList(val: IHistory[]) {
    if (val) {
      this._sessionList = val;
      this.getTestsData(val)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.testsResultsViewEmitter.emit(this.testsResultsView);
          this.isLoaded = true;
          this.isPupilLoaded = true;
        });
    }
  }

  constructor(
    private meta: Meta,
    private router: Router,
    private apiResultsService: ApiResultsService,
    private userDataHandlerService: UserDataHandlerService,
    private webStorageService: WebStorageService,
    private apiPlayerService: ApiPlayerService,
  ) {
    super();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.userData = this.userDataHandlerService.getUserData().getValue();
    this.meta.updateTag({ name: 'og:title', content: 'История тестов' });
    this.sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
    this.testSessionId = this.webStorageService.get(StorageKeys.TestSessionId);
    this.uncompletedTest = this.sessionStatus === 'Uncompleted test';
    this.isPupilPage = this.userInfo.role === 'pupil';
  }

  private getTestsData(sessionList: IHistory[]): Observable<any> {
    if (sessionList) {
      let currentObservable$: Observable<any> = of(null);
      if (this.pupil && this.pupil.regionId) {
        currentObservable$ = this.apiPlayerService.regionTestInfo(this.pupil.regionId).pipe(
          tap((testInfo: ITestInfo) => {
            this.testInfo = testInfo;
            switch (this.userInfo.role) {
              case UserRoles.pupil:
                this.sessionListView = this.sessionList.filter(el => el.variantId == testInfo['pupilVariantId'] && el.completed);
                break;
              case UserRoles.parent:
                this.sessionListView = this.sessionList.filter(el => el.variantId == testInfo['parentVariantId'] && el.completed);
                break;
              default:
                break;
            }
          }),
        );
      }

      return currentObservable$.pipe(
        switchMap(() => {
          this.sessionIdsList = this.sessionListView.map(el => el.sessionId);
          this.sessionListView.sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate));
          this.sessionListView.forEach(session => {
            this.testsResultsView.push({
              sessionListView: session,
              testsResults: null,
              resultsOpened: false,
            });
          });
          // запрашиваем результаты для shortResults по сессиям основного теста
          const passportOfTalentsSessions = this.sessionListView.filter(
            session => session.screeningTestId === this.testInfo.screeningTestId,
          );
          return from(passportOfTalentsSessions).pipe(
            mergeMap(session => {
              return this.apiResultsService.getResultsPage([session.sessionId]).pipe(
                tap((results: IGetDataForResultsPageResponse) => {
                  this.testsResultsView.map((tsv: ITestsResultsView) => {
                    if (tsv.sessionListView.sessionId === session.sessionId) {
                      tsv.testsResults = this.parseResults(results);
                    }
                  });
                }),
              );
            }),
            toArray(),
          );
        }),
      );
    } else {
      return of(null);
    }
  }

  public parseResults(oneTestResults): ITestsResults {
    return {
      skillsAttainment: oneTestResults.results.filter(d => d.objectType === 'SkillAttainment').sort((a, b) => this.sortTestResults(a, b)),
      interests: oneTestResults.results.filter(d => d.objectType === 'TalentAttainment').sort((a, b) => this.sortTestResults(a, b)),
      talents: oneTestResults.results.filter(d => d.objectType === 'Talent').sort((a, b) => this.sortTestResults(a, b)),
      fields: oneTestResults.results.filter(d => d.objectType === 'Field').sort((a, b) => this.sortTestResults(a, b)),
      skill: oneTestResults.results.filter(d => d.objectType === 'Skill').sort((a, b) => this.sortTestResults(a, b)),
      fieldDO: oneTestResults.results.filter(d => d.objectType === 'FieldDO').sort((a, b) => this.sortTestResults(a, b)),
    };
  }

  public sortTestResults(a: IResult, b: IResult): number {
    if (a.results[0] && b.results[0]) {
      return a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1;
    } else {
      return -1;
    }
  }

  public getResult(test: ITestsResultsView): void {
    test.resultsOpened = !test.resultsOpened;
  }

  public isMale(gender: string): boolean {
    return gender === Gender.Male;
  }

  public checkShortResults(test: ITestsResultsView): boolean {
    return test.sessionListView.screeningTestId === this.testInfo.screeningTestId;
  }

  public showTestName(test: ITestsResultsView): boolean {
    return this.userInfo.role != 'pupil';
  }

  private get sessionList(): IHistory[] {
    return this._sessionList;
  }

  public navigateStartTest() {
    this.router.navigate(['/test-na-professiyu']);
  }

  public navigateContinueTest() {
    this.router.navigate(['/test-na-professiyu/' + this.testSessionId]);
  }
}
