import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { ButtonComponent } from '@profilum-components/button/button.component';
import { ButtonType, CheckboxType } from '@profilum-collections/common.collections';

import { FormGeneratorService } from './services/form-generator.service';
import { JsonFormControls, JsonFormData } from './interfaces/form.interface';
import { InputComponent } from './components/inputs/input-component/input.component';
import { FormErrorsComponent } from './components/errors-component/form-errors.component';
import { ToggleComponent } from './components/inputs/toggle/toggle.component';
import { DatepickerComponent } from './components/inputs/datepicker/datepicker.component';
import { DropdownComponent } from './components/inputs/dropdown/dropdown.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';

@Component({
  selector: 'prf-form',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./styles/index.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgForOf,
    NgClass,
    ButtonComponent,
    InputComponent,
    FormErrorsComponent,
    ToggleComponent,
    DatepickerComponent,
    DropdownComponent,
    CheckboxComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGeneratorComponent extends UnsubscribeComponent implements OnChanges, OnInit {
  public form: FormGroup;
  public defaultButtonText: string = 'Подтвердить';
  public jsonFormData = signal<JsonFormData | null>(null);

  @Input() private configName: string;
  @Input() private initialData: Record<string, any>;
  @Input() public acceptButton: string = '';
  @Input() public cancelButton: string = '';
  @Input() public additionalStyleClasses: string[] = [];
  @Input() public isCancelButtonDisabled: boolean = false;
  @Input() public isButtonsVisible: boolean = true;

  @Output() public submitEmitter = new EventEmitter<any>();
  @Output() public cancelButtonEmitter = new EventEmitter<any>();
  @Output() public iconClickEmitter = new EventEmitter<any>();
  @Output() public formChangesEmitter = new EventEmitter<Observable<any>>();

  private formBuilder = inject(FormBuilder);
  private formGeneratorService = inject(FormGeneratorService);

  public ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.createForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.configName?.firstChange) {
      this.createControls(this.jsonFormData().controls);
    }
  }

  public get isFormValid(): boolean {
    return this.form.valid;
  }

  public get isFormPristine(): boolean {
    return this.form.pristine;
  }

  public get formErrors(): ValidationErrors {
    return this.form.errors;
  }

  public get formValue(): any {
    return this.form.value;
  }

  public onCancelButtonClick(): void {
    this.cancelButtonEmitter.emit();
  }

  public emitFormChangesObservable(): void {
    if (this.formChangesEmitter.observed) {
      this.formChangesEmitter.emit(this.form.valueChanges);
    }
  }

  public onSubmit() {
    if (this.form.valid) {
      this.submitEmitter.emit(this.form.value);
    }
  }

  public resetForm(): void {
    this.form.reset({ ...this.initialData });
  }

  public handleIconClick(clickedIconControl: JsonFormControls): void {
    if (clickedIconControl?.icon === 'clear') {
      this.form.get(clickedIconControl.name).setValue(null);
    }
    this.iconClickEmitter.emit(clickedIconControl);
  }

  private createControls(controls: JsonFormControls[]): void {
    for (const control of controls) {
      const validatorsToAdd = [];

      let customValidator: ValidationErrors = null;
      const validatorByType: ValidationErrors = this.formGeneratorService.setValidatorsByType(control);
      if (validatorByType) {
        validatorsToAdd.push(validatorByType);
      }

      if (control?.validators) {
        for (const [key, value] of Object.entries(control.validators)) {
          switch (key) {
            case 'min':
              validatorsToAdd.push(Validators.min(value));
              break;
            case 'max':
              validatorsToAdd.push(Validators.max(value));
              break;
            case 'required':
              if (value) {
                validatorsToAdd.push(Validators.required);
              }
              break;
            case 'requiredTrue':
              if (value) {
                validatorsToAdd.push(Validators.requiredTrue);
              }
              break;
            case 'email':
              if (value) {
                validatorsToAdd.push(Validators.email);
              }
              break;
            case 'minLength':
              validatorsToAdd.push(Validators.minLength(value));
              break;
            case 'maxLength':
              validatorsToAdd.push(Validators.maxLength(value));
              break;
            case 'pattern':
              validatorsToAdd.push(Validators.pattern(value));
              break;
            case 'nullValidator':
              if (value) {
                validatorsToAdd.push(Validators.nullValidator);
              }
              break;
            case 'customValidator':
              value.forEach(validator => {
                customValidator = this.formGeneratorService.setCustomValidator(control, validator);
                if (customValidator) {
                  validatorsToAdd.push(customValidator);
                }
              });

              break;
            default:
              break;
          }
        }
      }

      this.form.addControl(control.name, this.formBuilder.control(control.initialValue, validatorsToAdd));
    }

    if (this.jsonFormData()?.customFormValidators?.length) {
      this.form.setValidators(this.formGeneratorService.getCustomFormValidators(this.jsonFormData(), this.form));
    }
  }

  public createForm(): void {
    this.formGeneratorService
      .getFormData(this.configName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: response => {
          this.jsonFormData.set(this.initialData ? this.formGeneratorService.setInitialFormValues(response, this.initialData) : response);
          this.createControls(this.jsonFormData().controls);
          this.emitFormChangesObservable();
        },
        error: caughtError => {
          /*TODO нужен новый компонент нотификации*/
          throw caughtError;
        },
      });
  }

  public formControlGetter(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  protected readonly ButtonType = ButtonType;
  protected readonly CheckboxType = CheckboxType;

  public setCheckboxValue($event: string | { id: number; value: string }, control: JsonFormControls) {
    console.log($event);
    console.log(control);
  }
}
