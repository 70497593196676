import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import {
  ApiAdminsService,
  ApiCoursesMaterialsService,
  AppSettingsService,
  B2gSaasService,
  ICourseMaterialExt,
  IData,
  IGetCourseByClassResponse,
  IPassedLessons,
  ISchool,
  ISchoolClass,
  RamStorageService,
  StorageKeys,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { UserRoles } from 'app/shared/enums/userroles.enum';
import { TeacherPanelService } from 'app/pages/control-panel/teacher/teacher-panel.service';
import { TeachersJournalService } from 'app/shared/dashboard/career-education/teachers-journal/teachers-journal.service';
import { MenuItems } from 'app/shared/enums/menu.enums';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import {
  IMappedLessonData,
  IMappedLessonWithCourseData,
} from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TeacherClassCoursesService } from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import { IAssigningCourseData } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

interface IProfile {
  userId: string;
  userRole: string;
  firstName: string;
}

export enum ResultsButtons {
  Choices,
  Journal,
  Diagnostics,
}

@Component({
  selector: 'prf-page-result',
  templateUrl: './page-result.component.html',
  styleUrls: ['./page-result.component.scss'],
})
export class PageResultComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  public userRoles = UserRoles;
  public schoolClassLoaded: boolean = false;
  public schoolClass: ISchoolClass;
  public schoolTitle: string = '';
  public userRole: string = '';
  public userId: string = '';
  public urlSessionId: string = '';
  public sessionStatus: string = '';
  public profilumLogoUrl: string = AppSettingsService.settings.profilumLogoUrl;
  public profile: IProfile = {
    userId: '',
    userRole: '',
    firstName: '',
  };
  public resultsButtons = ResultsButtons;
  public currentActiveButton: ResultsButtons = ResultsButtons.Journal;
  public courseIds: string[];
  public alreadyClicksResultsButtons: ResultsButtons[] = []; // чтобы избавиться от пересоздания компонентов при использовании ng-if
  public lessons: IMappedLessonWithCourseData;
  public dataFetched: boolean = false;
  public fieldId: string = '';
  public teacherHasNoClasses$: Observable<boolean>;
  public popup: boolean = false;
  public ymItems = YmItems;
  public isAnyLessonGiven: boolean = false;
  private regionId: string = '';
  private course: ICourseMaterialExt;
  private schoolClassId: string;
  private copyText: string = '';
  public dictionary: DictionaryType;
  public isUserFromSalesB2S: boolean = false;
  public isHeaderHidden: boolean = false;

  public texts: Record<string, string> = {
    DEFAULT: `проходит профориентационный курс "Россия - мои горизонты", в рамках которого
         ребята узнают об особенностях образования и рынка труда нашего региона.
         Зарегистрируйте вашего ребенка, чтобы он получил доступ к необходимой для прохождения курса информации и диагностикам.
         Регистрация займет 2 минуты, перейдите по ссылке ниже и заполните форму.`,
    SALES_B2S: `будет проходить курс «Карьерная грамотность».
     Чтобы ваш ребенок получил доступ к учебным материалам и рекомендациям по профессиям,
      вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.`,
    DIAGNOSTIC: `Для нашего класса бесплатно проводят тест на профориентацию.\r\n
     В результате тестирования ребята получат отчёт об их сильных сторонах и рекомендации подходящих профессий. Вы также сможете посмотреть результаты.\r\n ️
      Чтобы ребёнок прошёл тестирование, вам нужно зарегистрировать его. Для этого перейдите по ссылке и заполните форму.\r\n`,
  };

  constructor(
    private meta: Meta,
    private teacherPanelService: TeacherPanelService,
    private activatedRoute: ActivatedRoute,
    private b2gSaasService: B2gSaasService,
    private translateService: TranslateService,
    private teacherService: TeacherPanelService,
    private apiAdminsService: ApiAdminsService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private overlayBusyService: OverlayBusyService,
    private rootScopeService: RootScopeService,
    private ramStorageService: RamStorageService,
    private utilsService: UtilsService,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private webStorageService: WebStorageService,
    private clipboardService: ClipboardService,
    private userActionsService: UserActionsService,
    private userDataHandlerService: UserDataHandlerService,
    private teachersJournalService: TeachersJournalService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    ramStorageService.set(StorageKeys.SelectedMenuItem, MenuItems.Results);
    this.isUserFromSalesB2S = this.regionId === '2683eee3-306b-49a3-a7b9-7ce28df17251';
    this.teacherHasNoClasses$ = ramStorageService.get(StorageKeys.TeacherHasNoClasses).pipe(
      tap((teacherHasNoClasses: boolean) => {
        if (teacherHasNoClasses) {
          this.overlayBusyService.hide();
        }
      }),
    );
    this.userActionsService.setInitializationTime([
      YmItems.T_Results_Selections,
      YmItems.T_Results_Diagnostics,
      YmItems.T_Results_Register,
    ]);
    this.getTranslation('SHARED.RESULT.S')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation['SHARED.RESULT.S'],
        }),
      );
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.userId = this.webStorageService.get(StorageKeys.UserId);
    this.sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
    this.profile = {
      userId: this.userId,
      userRole: this.userRole,
      firstName: this.webStorageService.get(StorageKeys.FirstName),
    };

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params['ssid']) {
        this.urlSessionId = params['ssid'];
      }
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queryParams: Params) => {
      if (queryParams?.hiddenHeader) {
        this.isHeaderHidden = queryParams.hiddenHeader;
      }
    });
  }

  public ngOnInit(): void {
    switch (this.userRole) {
      case this.userRoles.teacher:
        this.loadInfoForTeacher();
        break;
      case this.userRoles.schooladmin:
        this.loadInfoForSchoolAdmin();
        break;
      default:
        break;
    }
    this.regionId = this.webStorageService.get(StorageKeys.RegionId);
  }

  public printPage(): void {
    window.print();
  }

  public getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public setActiveButton(currentActiveButton: ResultsButtons): void {
    if (this.alreadyClicksResultsButtons.indexOf(currentActiveButton) === -1) {
      this.alreadyClicksResultsButtons.push(currentActiveButton);
    }

    this.currentActiveButton = currentActiveButton;
    const uaParams: IData = {
      SchoolClassId: this.schoolClassId,
    };
    if (this.schoolClassId) {
      switch (currentActiveButton) {
        case this.resultsButtons.Choices: {
          this.userActionsService.log(YmItems.T_Results_Selections, uaParams);
          break;
        }
        case this.resultsButtons.Journal: {
          this.userActionsService.log(YmItems.T_Results_Register, uaParams);
          break;
        }
        case this.resultsButtons.Diagnostics: {
          this.userActionsService.log(YmItems.T_Results_Diagnostics, uaParams);
          break;
        }
        default:
          break;
      }
    }
  }

  public seeResults([currentActiveButton, field]: [ResultsButtons, string]): void {
    // if (field) {
    //   this.fieldId = field;
    // }
    // this.setActiveButton(currentActiveButton);
  }

  public copyPupilRegLink(): void {
    const copyTextKG: string =
      `Здравствуйте, уважаемые родители ${this.schoolClass.className} класса!\r\n` + 'Наш класс ' + this.texts.DEFAULT;

    const copyTextKGFromB2S: string =
      `Здравствуйте, уважаемые родители ${this.schoolClass.className} класса!\r\n` + 'Наш класс ' + this.texts.SALES_B2S;

    const copyTextDiagnostic: string = `Уважаемые родители ${this.schoolClass.className} класса!\r\n` + this.texts.DIAGNOSTIC;

    this.popup = true;
    this.copyText = !this.courseIds?.length ? copyTextDiagnostic : this.isUserFromSalesB2S ? copyTextKGFromB2S : copyTextKG;
    const copyLink: string = this.schoolClass.shortLink ? this.schoolClass.shortLink : this.schoolClass.parentRegistrationLink;
    this.clipboardService.copy(this.copyText + ' ' + copyLink);
  }

  private loadInfoForTeacher(): void {
    this.overlayBusyService.show();
    // this.setActiveButton(this.resultsButtons.Choices);
    this.rootScopeService.currentCLass
      .pipe(
        switchMap((currentCLass: IAssigningCourseData) => {
          this.schoolClass = currentCLass.schoolClass as ISchoolClass;
          this.course = currentCLass.course as ICourseMaterialExt;
          this.courseIds = this.course?.id ? [this.course.id] : [];
          this.schoolClassLoaded = !!this.schoolClass;
          this.schoolClassId = this.schoolClass.id;

          return this.b2gSaasService.getSchool() as Observable<ISchool>;
        }),
        tap((school: ISchool) => (this.schoolTitle = school.number)),
        filter(() => {
          this.dataFetched = true;

          if (this.courseIds?.length > 0 && this.schoolClass.pupilsCount > 1) {
            return true;
          }

          this.overlayBusyService.hide();
          return false;
        }),
        switchMap(() => {
          return this.teachersJournalService.getLessons(this.schoolClass.id, this.courseIds);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: (mappedLessons: IMappedLessonData[]) => {
          const newPassedLessonsData = this.webStorageService.get(StorageKeys.NewPassedLessons);

          this.lessons = {
            course: this.course,
            mappedLessonData: mappedLessons,
            currentClass: this.schoolClass,
          };
          if (newPassedLessonsData) {
            this.lessons.course.passedLessons = newPassedLessonsData as IPassedLessons[];
            this.webStorageService.set(StorageKeys.NewPassedLessons, null);
          }
          this.isAnyLessonGiven = !!this.course?.passedLessons.some((el: IPassedLessons) => el.isPassed);
          this.overlayBusyService.hide();
        },
        error: () => {
          this.overlayBusyService.hide();
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        },
      });
  }

  private loadInfoForSchoolAdmin(): void {
    this.overlayBusyService.show();
    // this.setActiveButton(this.resultsButtons.Choices);
    this.activatedRoute.queryParams
      .pipe(
        filter(params => params && params['schoolClassId']),
        switchMap(params => {
          this.schoolClassId = params['schoolClassId'];

          return this.b2gSaasService.getSchool() as Observable<ISchool>;
        }),
        switchMap(school => {
          return this.apiAdminsService.getSchoolClassesExtended(school.id) as Observable<ISchoolClass[]>;
        }),
        tap(classes => {
          this.schoolClass = classes?.find(schoolClass => schoolClass.id === this.schoolClassId);
          this.schoolClassLoaded = !!this.schoolClass;
        }),
        switchMap(() => {
          return this.apiCoursesMaterialsService.getCourseByClass(this.schoolClass.id);
        }),
        filter((response: IGetCourseByClassResponse) => {
          this.dataFetched = true;
          if (response.courseIds?.length > 0 && this.schoolClass.totalPupilsCount > 1) {
            this.courseIds = response.courseIds;
            return true;
          }

          this.overlayBusyService.hide();
          return false;
        }),
        switchMap((response: IGetCourseByClassResponse) => {
          return combineLatest([
            this.teachersJournalService.getLessons(this.schoolClass.id, response.courseIds),
            this.teacherClassCoursesService.getCourseMaterialWithPassedData(response.courseIds?.[0], this.schoolClass),
          ]);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: ([mappedLessons, course]) => {
          this.lessons = { course, mappedLessonData: mappedLessons, currentClass: this.schoolClass };
          this.isAnyLessonGiven = !!course?.passedLessons.some((el: IPassedLessons) => el.isPassed);
          this.overlayBusyService.hide();
        },
        error: err => {
          this.overlayBusyService.hide();
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        },
      });
  }
}
