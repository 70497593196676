<div *ngIf="!isLoading; else loading" class="land-body scrollable-container">
  <div class="land-main-section-2">
    <div class="land-content">
      <div class="land-header-row-2">
        <a routerLink="/" class="land-header-logo-2 w-inline-block">
          <div class="profilum_logo">
            <img src="./profilum-assets/images/landing/logo.svg" alt="Profilum logo" />
          </div>
        </a>
        <div class="land-header-column">
          <a [ngClass]="routeAnimationsElements" routerLink="/login" class="land-header-button w-button">
            {{ 'SHARED.ENTRANCE' | translate }}
          </a>
        </div>
      </div>
      <div class="land-main-block">
        <div class="main-block">
          <h1 class="land-h1-2" [ngClass]="routeAnimationsElements">
            <span class="underline">Профнавигационная</span>
            <span class="underline">система</span>
          </h1>
          <p class="land-subtitle-h1-2" [ngClass]="routeAnimationsElements">
            Помогаем школьникам выбрать будущую профессию и спланировать свой путь к ней
          </p>
          <a [ngClass]="routeAnimationsElements" routerLink="/login" class="button land-new-green-btn w-button">{{
            'SHARED.ENTER_PERSONAL' | translate
          }}</a>
        </div>
        <img
          [ngClass]="routeAnimationsElements"
          src="./profilum-assets/images/landing/land-main-image.png"
          width="699"
          srcset="
            ./profilum-assets/images/landing/land-main-image-p-500.png  500w,
            ./profilum-assets/images/landing/land-main-image.png       1398w
          "
          sizes="(max-width: 479px) 92vw, (max-width: 767px) 81vw, (max-width: 991px) 90vw, 699px"
          alt=""
          class="land-main-image"
        />
      </div>
    </div>
  </div>
  <div class="land-content">
    <!--    <div *ngIf="isParentMeetingBannerShown" class="land-people-change-country-section">-->
    <!--      <a href="https://my.mts-link.ru/j/83145241/1357829671" target="_blank">-->
    <!--        <picture [ngClass]="routeAnimationsElements">-->
    <!--          <img src="./profilum-assets/images/banner/parent-meeting-banner.png" alt="Родительское собрание" />-->
    <!--        </picture>-->
    <!--      </a>-->
    <!--    </div>-->
    <!--    <div *ngIf="isPeopleChangeCountryBannerShown" class="land-people-change-country-section">-->
    <!--      <a href="https://xn&#45;&#45;80aicnnegcwjlgj1ncm.xn&#45;&#45;80aq0a.xn&#45;&#45;p1ai/" target="_blank">-->
    <!--        <picture [ngClass]="routeAnimationsElements">-->
    <!--          <source media="(max-width: 500px)" srcset="./profilum-assets/images/landing/people_changes_country_mobile.png" />-->
    <!--          <source media="(max-width: 999px)" srcset="./profilum-assets/images/landing/people_changes_country_tablet.png" />-->
    <!--          <img src="./profilum-assets/images/landing/people_changes_country_desktop.png" alt="#странуменяютлюди" />-->
    <!--        </picture>-->
    <!--      </a>-->
    <!--    </div>-->
    <div class="land-advantages-section">
      <div class="land-advantages-row">
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="./profilum-assets/images/icons/Idea_big.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Выявление талантов</h3>
          <p class="land-advantages-text">Доступ к современным инструментам диагностики и профориентации</p>
        </div>
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="./profilum-assets/images/landing/Chat.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Индивидуальные рекомендации</h3>
          <p class="land-advantages-text">
            Система поможет максимально эффективно использовать городскую инфраструктуру для знакомства с перспективными профессиями.
          </p>
        </div>
        <div class="land-advantages-column" [ngClass]="routeAnimationsElements">
          <img src="./profilum-assets/images/landing/Tie.svg" alt="" class="landadvantages-icon" />
          <h3 class="land-advantages-h3">Поиск новых интересов</h3>
          <p class="land-advantages-text">Возможность найти новые увлечения и участвовать в мероприятиях города</p>
        </div>
      </div>
    </div>
    <div class="land-users-section">
      <h2 class="land-h2">Для кого этот сервис?</h2>
      <p class="land-subtitle-h2">
        Профнавигация – это командная работа. Мы создали профориентационный сервис, который помогает школьникам, родителям и учителям
        работать вместе.
      </p>
      <div class="land-user-tabs">
        <div class="land-user-tabs-menu" [ngClass]="routeAnimationsElements">
          <div class="land-user-tabs-menu-row">
            <a [ngClass]="{ 'w--current': swiperIndex === 0 }" (click)="toggleOverlayControls(0)" class="land-user-tab-link w-inline-block">
              <div class="land-user-tab-link-text">Ученикам и родителям</div>
            </a>
            <a
              [ngClass]="{ 'w--current': swiperIndex === 1 }"
              (click)="toggleOverlayControls(1)"
              class="land-user-tab-link w-inline-block w--current"
            >
              <div class="land-user-tab-link-text">Учителям и школам</div>
            </a>
          </div>
        </div>

        <swiper-container
          #swiperComponent
          prfSwiperElement
          init="false"
          [config]="swiperConfig"
          (indexChange)="swiperIndexChange($event)"
          class="professions-row"
        >
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img src="./profilum-assets/images/landing/swiper1.jpg" class="land-user-image" alt="" />
              </div>
              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./profilum-assets/images/landing/Test.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Диагностика</h4>
                    <p class="land-user-text">Выявление навыков, интересов и талантов ребенка</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./profilum-assets/images/icons/Idea.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Рекомендации профессий</h4>
                    <p class="land-user-text">Каталог перспективные профессии с заданиями для погружения в профессию</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./profilum-assets/images/landing/Free_work.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Образование</h4>
                    <p class="land-user-text">Возможности дополнительного образования, профильные мероприятия и курсы</p>
                  </div>
                </div>
              </div>
            </div>
            <!--<a routerLink="/registration" class="button land-green-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>-->
          </swiper-slide>
          <swiper-slide class="land-user-tab-pane swiper-slide">
            <div class="land-user-row">
              <div class="land-user-column _1">
                <img class="land-user-image" src="./profilum-assets/images/landing/swiper2.jpg" alt="" />
              </div>
              <div class="land-user-column _2">
                <div class="land-user-adv">
                  <img src="./profilum-assets/images/landing/Hat.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Актуальные знания по профнавигации</h4>
                    <p class="land-user-text">Информация о перспективных профессиях и потребностях городской экономики.</p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./profilum-assets/images/icons/Idea.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Профили талантов</h4>
                    <p class="land-user-text">
                      Аналитика по результатам диагностики. Формирование профиля талантов класса и школы, информация о внеклассной
                      активности учеников.
                    </p>
                  </div>
                </div>
                <div class="land-user-adv">
                  <img src="./profilum-assets/images/landing/Book.svg" alt="" class="land-user-adv-icon" />
                  <div class="land-user-adv-column">
                    <h4 class="land-user-h4">Методическая поддержка</h4>
                    <p class="land-user-text">
                      Материалы для сопровождения профориентационной работы, индивидуальные рекомендации по работе с классом.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--<a routerLink="/registration" class="button land-green-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>-->
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="land-registration-section">
      <div class="container-970 w-container">
        <div class="land-reg-row">
          <div class="land-reg-column">
            <h2 class="land-h2 reg">Направляйте своего ребёнка</h2>
            <p class="land-subtitle-h2 reg">
              Узнайте сильные и слабые стороны вашего ребёнка и получите рекомендации по построению индивидуальной траектории.
            </p>
            <!--<a routerLink="/registration" class="button land-green-btn w-button">{{ 'SHARED.REGISTER' | translate }}</a>-->
          </div>
          <img src="./profilum-assets/images/landing/reg-image.svg" alt="" class="land-reg-image" />
        </div>
      </div>
    </div>
    <footer class="land-footer">
      <div class="w-container footer-container">
        <div class="footer-sponsors-block">
          <div class="footer-information">
            <div class="land-footer-text">© {{ nowDate | date: 'yyyy' }} Профилум.<br />Все права защищены.</div>
            <div>
              <div>
                <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.USED_PRIVACY_DATA' | translate
                }}</a>
              </div>
              <div>
                <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.AGREEMENT.USER_AGREEMENT' | translate
                }}</a>
              </div>
            </div>
            <div>
              <img src="./profilum-assets/images/logos/profilum-logo.svg" alt="Profilum logo" />
            </div>
          </div>
        </div>
        <div class="footer-contacts">
          <div class="land-footer-contacts">
            <div class="footer-contacts-small">поддержка</div>
            <a href="mailto:help@profilum.ru" class="land-footer-link"><b>help&#64;profilum.ru</b></a>
            <a href="tel:88005004105" class="land-footer-link">
              <b>8 800 500 4105</b><br />
              (звонок бесплатный)
            </a>
          </div>
        </div>
      </div>
      <div class="footer-mobile">
        <div class="footer-mobile-down">
          <div class="footer-mobile-info">
            <div class="footer-mobile-contacts">
              <div>
                <a href="{{ getDocsRoute() + '/PrivacyPolicy.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.USED_PRIVACY_DATA' | translate
                }}</a>
              </div>
              <div>
                <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank" class="land-footer-link">{{
                  'SHARED.AGREEMENT.USER_AGREEMENT' | translate
                }}</a>
              </div>
              <div class="land-footer-text">© {{ nowDate | date: 'yyyy' }} Профилум.<br />Все права защищены.</div>
            </div>
          </div>
          <div class="footer-mobile-mails">
            <div class="footer-contacts-small">поддержка</div>
            <a href="mailto:help@profilum.ru" class="land-footer-link">help&#64;profilum.ru</a>
            <a href="tel:88005004105" class="land-footer-link">
              8 800 500 4105 <br />
              (звонок бесплатный) </a
            ><br />
            <div class="logo_footer">
              <img src="./profilum-assets/images/landing/logo.svg" alt="Profilum logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>

<ng-template #loading></ng-template>
