<div class="prf-login-page">
  <div class="prf-login">
    <a routerLink="/" class="prf-login-logo">
      <img src="./profilum-assets/images/eurochem/logo_big.png" alt="EUROCHEM" />
    </a>
    <ng-container *ngTemplateOutlet="isReferralValid ? DEFAULT_TEMPLATE : NOT_VALID_REFERRAL_TEMPLATE" />
  </div>
  <prf-page-footer class="prf-footer"></prf-page-footer>
</div>

<ng-template #DEFAULT_TEMPLATE>
  <prf-form
    #parentRegistrationForm
    [configName]="formConfig"
    [acceptButton]="confirmButtonText"
    [isButtonsVisible]="false"
    [class.display-none]="isVisibleCode"
  />
  @if (isVisibleCode) {
    <div class="code-input-wrapper">
      <p class="description">
        {{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.CALL_WILL_SENT_TO_NUMBER }}
        <span class="phone-number">{{ parentRegistrationForm.formValue.phone }}</span
        >. {{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.CALL_WILL_SENT_TO_NUMBER_END }}
      </p>
      <prf-code-input #codeInput [codeLength]="4" [currentState]="codeInputFieldState" (codeCompleted)="verifyInputCode($event)" />
      <p *ngIf="codeInputFieldState === CodeInputStateEnum.ERROR" class="code-input-state-message error-text">
        {{ SendCodeErrorsEnum.ERROR }}
      </p>
      <p *ngIf="codeInputFieldState === CodeInputStateEnum.DISABLED" class="code-input-state-message disabled-text">
        {{ SendCodeErrorsEnum.EXCEED_ATTEMPTS }}
      </p>
    </div>
  }
  @if (!isVisibleCode) {
    <prf-button
      [isFullWidth]="true"
      [buttonTitle]="confirmButtonText"
      [type]="ButtonType.Eurochem"
      [disabled]="!parentRegistrationForm.isFormValid"
      (emitClick)="sendCode()"
    />
  }
  @if (isVisibleCode) {
    <prf-button
      class="text-center"
      [disabled]="!!secondsToNextCallRequest"
      [isFullWidth]="true"
      [type]="ButtonType.Eurochem"
      [buttonTitle]="
        EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.REQUEST_NEW_CALL +
        ' ' +
        (secondsToNextCallRequest ? (secondsToNextCallRequest | date: 'mm:ss') : '')
      "
      (emitClick)="sendCode()"
    />
  }
</ng-template>

<ng-template #NOT_VALID_REFERRAL_TEMPLATE>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL1 }}</p>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL2 }}</p>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL3 }}</p>
</ng-template>
