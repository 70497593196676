<ng-container [ngSwitch]="userInfo.role">
  <ng-container *ngSwitchCase="'admin'">
    <prf-admin-menu [professionActive]="true" [adminLevel]="adminLevel"></prf-admin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'schooladmin'">
    <prf-schooladmin-menu [professionActive]="true"></prf-schooladmin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'adminDO'">
    <prf-admin-menu [professionActive]="true" [adminLevel]="adminLevel" [itemsHide]="['landing', 'users', 'professions', 'centers']">
    </prf-admin-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'tutor'">
    <prf-tutor-menu [innerRoute]="true"></prf-tutor-menu>
  </ng-container>

  <ng-container *ngSwitchCase="'director'">
    <prf-director-menu></prf-director-menu>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <prf-menu></prf-menu>
  </ng-container>
</ng-container>

<div class="content-section">
  <div class="container container-1000 prof-page-cont">
    <ng-container *ngIf="checkHeaderButton(); else headerWithoutButton">
      <prf-page-header
        [backButtonName]="'SHARED.ALL_PROFESSIONS' | translate"
        [backButtonUrl]="userInfo.role.includes('admin') ? '/' + userInfo.role + '/professions' : '/professions'"
        [title]="'HEADER.PROF' | translate"
        [showClassDropdown]="false"
      >
      </prf-page-header>
    </ng-container>

    <ng-template #headerWithoutButton>
      <prf-page-header [title]="'HEADER.PROF' | translate"> </prf-page-header>
    </ng-template>

    <div class="profession-page-section" *ngIf="profession && dataFetched">
      <div class="prof-page-white-column">
        <div *ngIf="checkProfession()" class="prof-municipality w-inline-block" style="position: relative">
          <img src="./profilum-assets/images/icons/fire-profession.svg" class="prof-municipality-image" />
          <div class="prof-municipality-text">
            {{ 'SHARED.PROFESSION_NARRATIVE' | translate }}
          </div>
        </div>
        <h1 class="profession-page-heading-1">{{ profession.name }}</h1>
        <div class="mobile-block">
          <ng-container *ngIf="userInfo.role === 'pupil'">
            <span (mouseover)="showTooltipAdd = true" (mouseleave)="showTooltipAdd = false">
              <a
                *ngIf="!isGoal; else favorite"
                [attr.disabled]="testCompleted ? null : true"
                (click)="addToGoals()"
                class="button add-goal w-inline-block"
                style="position: relative"
              >
                <img src="./profilum-assets/images/icons/Flag-white.svg" class="goal-button-icon" />
                <div>{{ 'SHARED.ADD_IN_TARGET' | translate }}</div>
                <div *ngIf="showTooltipAdd && testCompleted !== true" class="info-popup recommendation w-hidden-small w-hidden-tiny">
                  <p>{{ 'SHARED.TEST_REQUIRED' | translate }}</p>
                  <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
                </div>
              </a>
            </span>
          </ng-container>

          <prf-recommendation-menu
            *ngIf="userInfo.role === 'parent' || userInfo.role === 'teacher'"
            [courseId]="profession.id"
            [recommendationType]="'Profession'"
          >
          </prf-recommendation-menu>
        </div>
        <div class="prof-page-text" innerHtml="{{ profession.longDescription }}"></div>
        <div class="prof-info-block">
          <h2 class="prof-h2">Путь к профессии "{{ profession.name | trim }}"</h2>
          <prf-steps-trajectory [hideButton]="true" [profession]="profession"></prf-steps-trajectory>
        </div>
        <div>
          <h2 class="prof-h2">Больше интересного о профессии</h2>
          <div class="prof-info-menu" #profInfoMenu>
            <div class="prof-info-menu-row">
              <a
                class="prof-menu-link"
                *ngFor="let item of contentMenu"
                [ngClass]="{ 'w--current': currentContent == item }"
                (click)="currentContent = item; prepareData()"
                >{{ item }}</a
              >
            </div>
          </div>
          <div class="prof-info-tabs">
            <div class="prof-info-tab-pane">
              <ng-container *ngIf="currentContent === 'Видео'">
                <ng-container *ngTemplateOutlet="videos"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Фильмы'">
                <ng-container *ngTemplateOutlet="movies"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Профессия в лицах'">
                <ng-container *ngTemplateOutlet="faces"></ng-container>
              </ng-container>
              <ng-container *ngIf="currentContent === 'Книги и статьи'">
                <ng-container *ngTemplateOutlet="articles"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="mobile-block" *ngIf="showSalary">
          <h4 class="average-salary-heading">Средняя зарплата на разных этапах карьерного роста</h4>
          <ul class="average-salary-list w-list-unstyled">
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист высшей категории</div>
                <div class="wage-amount">
                  {{ profession.maxSalary | number }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист со средним опытом</div>
                <div class="wage-amount">
                  {{ profession.averageSalary | number }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _2"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Начинающий специалист</div>
                <div class="wage-amount">
                  {{ profession.minSalary | number }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _3"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="prof-page-gray-column">
        <ng-container *ngIf="userInfo.role === 'pupil'">
          <span (mouseover)="showTooltipAdd = true" (mouseleave)="showTooltipAdd = false">
            <a
              *ngIf="!isGoal; else favorite"
              [attr.disabled]="testCompleted ? null : true"
              (click)="addToGoals()"
              class="button add-goal w-inline-block"
              style="position: relative"
            >
              <img src="./profilum-assets/images/icons/Flag-white.svg" class="goal-button-icon" />
              <div>{{ 'SHARED.ADD_IN_TARGET' | translate }}</div>
              <div *ngIf="showTooltipAdd && testCompleted !== true" class="info-popup recommendation w-hidden-small w-hidden-tiny">
                <p>{{ 'SHARED.TEST_REQUIRED' | translate }}</p>
                <img src="./profilum-assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
              </div>
            </a>
          </span>
        </ng-container>

        <prf-recommendation-menu
          *ngIf="userInfo.role === 'parent' || userInfo.role === 'teacher'"
          [courseId]="profession.id"
          [recommendationType]="'Profession'"
        >
        </prf-recommendation-menu>

        <div *ngIf="showSalary">
          <h4 class="average-salary-heading">Средняя зарплата на разных этапах карьерного роста</h4>
          <ul class="average-salary-list w-list-unstyled">
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист высшей категории</div>
                <div class="wage-amount">
                  {{ profession.maxSalary | number }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Специалист со средним опытом</div>
                <div class="wage-amount">
                  {{ profession.averageSalary | number }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _2"></div>
              </div>
            </li>
            <li class="average-salary-li">
              <div class="average-salary-row">
                <div class="average-salary-post">Начинающий специалист</div>
                <div class="wage-amount">
                  {{ profession.minSalary | number }}
                  {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
                </div>
              </div>
              <div class="average-salary-line-wrapper">
                <div class="average-salary-line _3"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <prf-similar-professions></prf-similar-professions>

    <prf-page-footer></prf-page-footer>
  </div>
</div>

<ng-template #faces>
  <div class="professionals-list">
    <a class="professional-card w-inline-block" *ngFor="let person of personsList; let i = index" (click)="checkPopup($event, popup)">
      <div class="professional-info-lb lb" #popup>
        <div class="lb-bg"></div>
        <div class="lb-block prof-popup">
          <div class="close-lb" (click)="popup.style.display = 'none'">
            <img src="./profilum-assets/images/icons/close-lb.svg" />
          </div>
          <div class="professional-info-row">
            <div class="professional-info-column _1">
              <p>{{ person.description }}</p>
            </div>
            <div class="professional-info-column _2">
              <div class="professional-photo prof-popup">
                <img src="{{ person.imagePath ? '/staticfiles' + person.imagePath : './profilum-assets/images/icons/no-photo_1.svg' }}" />
              </div>
              <div class="professional-name prof-popup">{{ person.name }}</div>
              <div class="professional-post">{{ profession.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="professional-photo">
        <img src="{{ person.imagePath ? '/staticfiles' + person.imagePath : './profilum-assets/images/icons/no-photo_1.svg' }}" />
      </div>
      <div class="professional-column">
        <div class="professional-name">{{ person.name }}</div>
        <div class="professional-post">{{ profession.name }}</div>
        <div class="professional-bio">
          <p class="professional-bio-text">{{ person.description }}</p>
          <div class="professional-read-more">подробнее</div>
        </div>
      </div>
    </a>
  </div>

  <div class="center-button" *ngIf="profession.persons.length > 2">
    <a
      class="white-button show-more w-button"
      (click)="personsList = profession.persons"
      *ngIf="personsList.length < profession.persons.length"
      >Показать всех</a
    >
    <a
      class="white-button show-more w-button"
      (click)="personsList = profession.persons.slice(0, 2); scroll()"
      *ngIf="personsList.length == profession.persons.length"
      >Скрыть</a
    >
  </div>
</ng-template>

<ng-template #movies>
  <ul class="films-list">
    <li *ngFor="let movieName of profession.movies">
      <div class="film-title">{{ movieName }}</div>
    </li>
  </ul>
</ng-template>

<ng-template #videos>
  <ng-container *ngFor="let video of videoList">
    <div class="frame-video">
      <iframe width="100%" height="380" [src]="video.url | safeUrl" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="prof-info-video-text">{{ video.name }}</div>
  </ng-container>
  <div class="center-button" *ngIf="profession.videos.length > 1">
    <a class="white-button show-more w-button" (click)="videoList = profession.videos" *ngIf="videoList.length < profession.videos.length"
      >Показать все видео</a
    >
    <a
      class="white-button show-more w-button"
      (click)="videoList = profession.videos.slice(0, 1); scroll()"
      *ngIf="videoList.length == profession.videos.length"
      >Скрыть видео</a
    >
  </div>
</ng-template>

<ng-template #favorite>
  <a class="button add-goal w-inline-block remove-goal" (click)="removeGoal()">
    <img src="./profilum-assets/images/icons/Flag-white.svg" class="goal-button-icon" />
    <div>Убрать из цели</div>
  </a>
</ng-template>

<ng-template #articles>
  <ul class="articles-list">
    <li class="articles-list-item" *ngFor="let article of profession.articles">
      <a class="article-link" [href]="sanitizeUrl(article.url)" target="_blank">
        {{ article.name }}
      </a>
    </li>
  </ul>
</ng-template>
